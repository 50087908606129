import { HomeLayout } from 'pages/Home'
import { LineDynamicPage } from 'pages/Home/Line/DynamicPage'
import { Video } from 'pages/Home/Live/Video'
import { LeagueGridPage } from 'pages/LeagueGrid'
import { ResultsPage } from 'pages/Results'
import { StatisticsPage } from 'pages/Statistics'
import { lazy } from 'react'

const EventById              = lazy(() => import('pages/Event/EventById'))
const AllSports              = lazy(() => import('pages/Home/Line/AllSports'))
const ChampionshipCategories = lazy(() => import('pages/Home/Line/Championship/Categories'))
const LiveBySport            = lazy(() => import('pages/Home/Live/BySport'))
const Favorite               = lazy(() => import('pages/Home/Live/Favorite'))
const TopPage                = lazy(() => import('pages/Home/Live/Top'))
const LineLeague             = lazy(() => import('pages/LineLeague'))
const PopularLeaguesPage     = lazy(() => import('pages/PopularLeagues'))
const SearchEventPage        = lazy(() => import('pages/SearchEvent'))
const TopExpressPage         = lazy(() => import('pages/TopExpress'))
const ForecastPage           = lazy(() => import('pages/Forecast'))

export const eventRoutes = [
  {
    path:    '/',
    element: <TopPage />,
  },
  {
    path:     'live',
    element:  <HomeLayout />,
    children: [
      {
        path:    'top',
        element: <TopPage short />,
      },
      {
        path:    'video',
        element: <Video />,
      },
      {
        path:    'favorites',
        element: <Favorite />,
      },
      {
        path:    ':id',
        element: <LiveBySport />,
      },
    ],
  },
  {
    path:     'line',
    element:  <HomeLayout />,
    children: [
      {
        path:    'all-sports',
        element: <AllSports />,
      },
      {
        path:    ':id',
        element: <LineDynamicPage />,
      },
    ],
  },
  {
    path:    'line/championship-:champId/:ids',
    element: <ChampionshipCategories />,
  },
  {
    path:    'live-event/:eventId',
    element: <EventById type="live" />,
  },
  {
    path:    'line-event/:eventId',
    element: <EventById type="line" />,
  },
  {
    path:    'top-express',
    element: <TopExpressPage />,
  },
  {
    path:    'forecast/create',
    element: <ForecastPage />,
  },
  {
    path:    'search-event',
    element: <SearchEventPage />,
  },
  {
    path:    'line-popular/:sportSlug/:popSlug/:eventId?',
    element: <PopularLeaguesPage />,
  },
  {
    path:    'line-league/:leagueId/:period/:eventId?',
    element: <LineLeague />,
  },
  {
    path:    'results',
    element: <ResultsPage />,
  },
  {
    path:    'statistics/:type/:id',
    element: <StatisticsPage />,
  },
  {
    path:    'league-grid/:id',
    element: <LeagueGridPage />,
  },
]
