import { ReactComponent as FireIcon } from 'assets/images/casino/icons/colored/fire.svg'
import { useMemo } from 'react'
import {
  cn,
  importImage,
} from 'utils'
import styles from './styles.module.scss'
import {
  fireCycleCounter,
  sparklesCycleCounter,
} from './utils'

export const RtpAnimations = ({ percent }) => {
  const [gifKey, animationKey] = useMemo(() => [sparklesCycleCounter(), fireCycleCounter()], [])

  return (
    <>
      <img
        src={importImage(`casino/gif/sparkles${gifKey}.gif`)}
        className={styles.sparklesGif}
        alt="Sparkles"
      />
      <div className={styles.fireProgress}>
        <div
          className={cn(styles.fireIndicator, percent >= 100 && styles.full)}
          style={{ height: `calc(50% + ${percent / 2}%)` }}
        >
          <FireIcon className={cn(styles.fireIcon, styles[`animation${animationKey}`])} />
        </div>
      </div>
    </>
  )
}
