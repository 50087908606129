import { FreeSpins } from 'components/Casino/FreeSpins'
import { Page } from 'components/Page'
import { CasinoPageSEOCollapse } from 'components/SEOCollapse/Casino'
import { getBanners } from 'pages/Casino/Banners/utils'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectTournamentsCount } from 'store/data/selectors'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const CasinoBannersPage = () => {
  const tournamentsCount = useSelector(selectTournamentsCount)

  return (
    <Page
      className="casino"
      bg="black"
    >
      <FreeSpins className={styles.freeSpins} />
      <div className={styles.banners}>
        {getBanners(tournamentsCount).map(({ name, to, image, count, ...restProps }) => (
          <Link
            key={name}
            to={to}
            className={cn(styles[name])}
            {...restProps}
          >
            <img
              src={image}
              className={styles.img}
              alt={name}
            />
            <div className={styles.counter}>{count}</div>
          </Link>
        ))}
      </div>
      <CasinoPageSEOCollapse />
    </Page>
  )
}
