import { Api } from 'api/Api'
import { Portrait } from 'components/Orientation'
import { useAmountController } from 'components/PaymentModal/AmountController'
import { MIN_AMOUNT } from 'components/PaymentModal/AmountController/utils'
import { BankPage } from 'components/PaymentModal/ByCard/BankPage'
import { handlePaymentMessage } from 'components/PaymentModal/ByCard/utils'
import { CardController } from 'components/PaymentModal/CardController'
import { OtherPaymentsLink } from 'components/PaymentModal/OtherPaymentsLink'
import { buildPaymentError } from 'components/PaymentModal/utils'
import { showErrorModal } from 'components/Popup'
import { SubmitBtn } from 'components/SubmitBtn'
import { STATUSES } from 'constants/statuses'
import { useListener } from 'hooks/useListener'
import { usePaymentsState } from 'hooks/usePaymentsState'
import {
  useCallback,
  useState,
} from 'react'
import styles from './styles.module.scss'

export const ByCard = () => {
  const [link, setLink] = useState('')

  const { state: { cards, card }, isHybridModal, closeModal, setState, getDetails } = usePaymentsState()
  const [AmountController, { value, onKeyboardClose, onValidate }]                  = useAmountController()

  const onIframeClose = () => setLink('')

  const onSuccess = () => {
    closeModal()
    getDetails()
  }

  const onMessage = useCallback(event => handlePaymentMessage(event, {
    closeModal,
    onIframeClose,
    onSuccess,
    isHybridModal,
  }), [])

  useListener('message', onMessage)

  const onSubmit = async () => {
    onValidate()
    onKeyboardClose()

    if (value >= MIN_AMOUNT) {
      try {
        const { status, data, messages } = await Api.payIn(value, card)

        if (status === STATUSES.SUCCESS) {
          return setLink(data.link)
        }

        showErrorModal(buildPaymentError(messages, closeModal))
      } catch {
        showErrorModal(buildPaymentError('Неизвестная ошибка', closeModal))
      }
    }
  }

  return (
    <div className={styles.byCard}>
      {AmountController}
      <CardController
        value={card}
        options={cards}
        onChange={setState}
      />
      <SubmitBtn
        className="ml-button"
        onClick={onSubmit}
      >
        Пополнить
      </SubmitBtn>
      <Portrait>
        <OtherPaymentsLink />
      </Portrait>
      <BankPage
        href={link}
        onClose={onIframeClose}
      />
    </div>
  )
}
