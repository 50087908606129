import { platform } from 'constants/platform'
import { STATUSES } from 'constants/statuses'
import { parseJSON } from 'utils/parse-json'
import { reactiveStorage } from 'utils/reactive-storage'
import emitter from 'utils/socket/emitter'
import { CASINO_API_URL } from 'utils/socket/utils'

export class CasinoWS {
  isManuallyClosing = false

  constructor() {
    this.connect()
  }

  buildURL = () => {
    const token = reactiveStorage.getItem('token')
    return `${CASINO_API_URL}?pl=${platform}${token ? `&sid=${token}` : ''}`
  }

  connect = () => {
    this.ws = new WebSocket(this.buildURL())

    this.ws.onmessage = event => {
      const { type, status, data } = parseJSON(event.data)
      emitter.emit(type, data, status !== STATUSES.SUCCESS)
    }

    this.ws.onclose = () => {
      if (!this.isManuallyClosing) {
        this.connect()
      }

      this.isManuallyClosing = false
    }

    this.ws.onopen = () => {
      emitter.setEntity(this.ws)
    }
  }

  reconnect = () => {
    this.ws.close()
  }

  destroy = () => {
    this.isManuallyClosing = true
    this.ws.close()
  }
}
