class ReactiveStorage {
  #observables = {}

  #notify(key, oldValue, newValue) {
    const callbacks = this.#observables[key]

    if (callbacks?.length) {
      callbacks.forEach((callback) => callback({ key, oldValue, newValue }))
    }
  }

  setItem(key, newValueRaw) {
    const oldValue = this.getItem(key)
    const newValue = typeof newValueRaw === 'object' ? JSON.stringify(newValueRaw) : newValueRaw
    localStorage.setItem(key, newValue)
    this.#notify(key, oldValue, newValue)
  }

  removeItem(key) {
    if (key in localStorage) {
      const oldValue = this.getItem(key)
      localStorage.removeItem(key)
      this.#notify(key, oldValue)
    }
  }

  getItem(key) {
    return localStorage.getItem(key)
  }

  observe(key, callback) {
    if (this.#observables[key]) {
      this.#observables[key].push(callback)
    } else {
      this.#observables[key] = [callback]
    }
  }

  unobserve(key, callback) {
    if (this.#observables[key]) {
      this.#observables[key] = this.#observables[key].filter(cb => cb.toString() !== callback.toString())
    }
  }
}

export const reactiveStorage = new ReactiveStorage()
