import { Api } from 'api/Api'
import { Placeholder } from 'components/Placeholder'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import SwipeModal from 'components/SwipeModal'
import { InfiniteCasino } from 'pages/UserHistoryPage/Casino/History/CasinoItem/InfiniteCasino'
import {
  casinoBetsCount,
  casinoPeriods,
} from 'pages/UserHistoryPage/Casino/History/utils'
import {
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  renderOptions,
  scrollTop,
} from 'utils'

const getPeriodFilters = (action, value, callback) => [
  { title: casinoPeriods[0], action: () => action(0), checked: +value === 0, callback },
  { title: casinoPeriods[1], action: () => action(1), checked: +value === 1, callback },
  { title: casinoPeriods[2], action: () => action(2), checked: +value === 2, callback },
]

export const CasinoHistory = ({ period, filter, setFilter, setPeriod }) => {
  const [bets, setBets]       = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage]       = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const onChangeOpen = useCallback(action => nextFilter => {
    action(nextFilter)
    setFilter(null)

    scrollTop()
  }, [])

  const getHistory = useCallback((page_id, isLoader = false, isAdded = false) => {
    if (isLoader) {
      showPreloader()
      setLoading(true)
    }

    Api.casinoHistory(period, page_id, casinoBetsCount)
      .then(({ data } = {}) => {
        const { transactions = [], has_more } = data

        setHasMore(has_more)
        setBets(prev => isAdded ? [...prev, ...transactions] : transactions)
      })
      .catch(() => setBets([]))
      .finally(() => {
          if (isLoader) {
            hidePreloader()
            setLoading(false)
          }
        },
      )
  }, [period])

  const getMore = () => {
    if (hasMore) {
      setPage(page + 1)
      getHistory(page + 1, false, true)
    }
  }

  useEffect(() => {
    setPage(0)
    getHistory(0, true)
  }, [period])

  const hasHistory = bets.length > 0

  return (
    <>
      {(hasHistory && (
        <InfiniteCasino
          transactions={bets}
          getMore={getMore}
        />
      )) || (!loading && <Placeholder name="casino" />)}
      <SwipeModal
        title="Период"
        onClose={() => setFilter(null)}
        isOpen={filter}
      >
        {renderOptions(getPeriodFilters(onChangeOpen(setPeriod), period))}
      </SwipeModal>
    </>
  )
}
