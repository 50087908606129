import { Api } from 'api/Api'

export const CentrifugoChannel = {
  BALANCE:          'balance:user#{{user}}',
  TOURNAMENT_PLACE: 'prize_tournament_place:id#{{id}}user#{{user}}',
  PROMO_CODE:       'promo_code:user#{{user}}',
  ONLINE:           'online:users',
  FREE_SPINS:       'balance:user#{{user}}:freespin',
  WAGER:            'balance:user#{{user}}:wager',
  SIGN_UP_STEP:     'registration_step:user#{{user}}',
  NOT_CALC_BETS:    'bet_not_calc:user#{{user}}',
}

export const getToken = async () => {
  try {
    const { data: { jwt } } = await Api.getCentrifugoJWT()
    return jwt
  } catch {
    return ''
  }
}