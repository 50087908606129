import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkIsAuth } from 'store/auth/actions'

const EVENT_TYPE = 'visibilitychange'

const VisibilityState = {
  VISIBLE: 'visible',
}

export const useVisibilityChange = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const onChange = () => {
      if (document.visibilityState === VisibilityState.VISIBLE) {
        dispatch(checkIsAuth())
      }
    }

    document.addEventListener(EVENT_TYPE, onChange)

    return () => {
      document.removeEventListener(EVENT_TYPE, onChange)
    }
  }, [])
}
