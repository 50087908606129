import App from 'components/App'
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { authRoutes } from 'router/auth'
import { casinoRoutes } from 'router/casino'
import { eventRoutes } from 'router/event'
import { staticRoutes } from 'router/static'
import { userRoutes } from 'router/user'

export const router = createBrowserRouter([
  {
    element:  <App />,
    children: [
      ...authRoutes,
      ...casinoRoutes,
      ...eventRoutes,
      ...staticRoutes,
      ...userRoutes,
    ],
  },
])
