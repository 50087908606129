export const types = {
  0: 'Спорт',
  1: 'Live',
  2: 'Все ставки',
}

export const periods = {
  0: '3 дня',
  1: 'Неделя',
  2: 'Месяц',
}

export const casinoPeriods = {
  0: '3 дня',
  1: 'Неделя',
  2: '2 недели',
}

export const casinoBetsCount = 50
