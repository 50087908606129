import Slots from 'components/Casino/Slots/Slots'
import { useIntersect } from 'hooks/useIntersect'
import { useEffect } from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const InfiniteSlots = ({
  games,
  getMore,
  loading,
  onScroll,
  className,
  hasDemo,
}) => {
  const [lastNode, isLastVisible] = useIntersect()

  const needRequest = !loading && isLastVisible

  useEffect(() => {
    if (needRequest) {
      getMore()
    }
  }, [needRequest])

  return (
    <div
      className={cn(styles.infiniteSlots, className)}
      onScroll={onScroll}
    >
      <Slots
        games={games}
        hasDemo={hasDemo}
      >
        {lastNode}
      </Slots>
    </div>)
}
