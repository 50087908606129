import styles from 'components/Keyboard/styles.module.scss'
import {
  INITIAL_VALUE,
  MAX_VALUE,
  numbers,
  toCorrectValue,
} from 'components/Keyboard/utils'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { cn } from 'utils'

export const Keyboard = ({ value: v, max: m, onChange, onClose, isVip, keyBar, userBets, className }) => {
  const value      = +v
  const max        = m === false ? MAX_VALUE : +m
  const min        = 0.1
  const valueStr   = String(v)
  const isMaxExist = +m > 0

  useEffect(() => {
    if (!max && value) {
      onChange(INITIAL_VALUE)
    }
  }, [value, max])

  const changeAmount = keyValue => {
    const keyString = keyValue.toString()
    const isFloat   = valueStr.includes('.')
    const decimal   = valueStr.split('.')[1]

    if (keyValue === 'backspace') {
      return valueStr.length > 0 && onChange(valueStr.slice(0, -1))
    }

    if (keyValue === '.' && isFloat || keyString === '0' && valueStr === '0' ||
      decimal && decimal.length > 1 || (value === max && !isVip)) {
      return false
    }

    if (keyValue === '.' && !valueStr) {
      return onChange('0.')
    }

    const nextValue = valueStr + keyValue
    const maxValue  = isVip ? MAX_VALUE : max

    onChange(toCorrectValue(+nextValue < maxValue ? nextValue : maxValue))
  }

  const barKeysHandler = (num, replace = false) => {
    if (max <= 0) {
      return
    }

    if (replace) {
      return onChange(num <= max ? num : max)
    }

    const nextValue = +((value + num).toFixed(2))

    if (nextValue >= 0 && nextValue <= max) {
      return onChange(nextValue)
    }

    return onChange(nextValue < 0 ? 0 : max)
  }

  const { plusMinus, bet1, bet2, bet3 } = userBets

  return (
    <div className={cn(styles.keyboard, className)}>
      {keyBar && (
        <div className={styles.header}>
          <div
            className={styles.headerBtn}
            data-value={`min: ${min}`}
            onClick={() => barKeysHandler(min, true)}
          />
          {!!plusMinus &&
            <div
              className={styles.headerBtn}
              data-value={`-${plusMinus}`}
              onClick={() => barKeysHandler(-plusMinus)}
            />}
          {Object.entries({ bet1, bet2, bet3 }).map(([key, value]) => (
            !!value &&
            <div
              key={key}
              className={styles.headerBtn}
              data-value={value}
              onClick={() => barKeysHandler(value, true)}
            />
          ))}
          {!!plusMinus &&
            <div
              className={styles.headerBtn}
              data-value={`+${plusMinus}`}
              onClick={() => barKeysHandler(plusMinus)}
            />}
          {max > 0 && isMaxExist &&
            <div
              className={styles.headerBtn}
              data-value={`max: ${max}`}
              onClick={() => barKeysHandler(max, true)}
            />}
        </div>
      )}
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.keysRow}>
            {numbers.top.map(num => (
              <div
                key={num}
                className={styles.mainBtn}
                data-value={num}
                onTouchStart={() => changeAmount(num)}
              />
            ))}
          </div>
          <div className={styles.keysRow}>
            {numbers.bottom.map(num => (
              <div
                key={num}
                className={styles.mainBtn}
                data-value={num}
                onTouchStart={() => changeAmount(num)}
              />
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.keysRow}>
            <div
              data-value="."
              className={styles.dotBtn}
              onTouchStart={() => changeAmount('.')}
            />
            <div
              className={styles.deleteBtn}
              onClick={() => changeAmount('backspace')}
            />
          </div>
          <div className={styles.keysRow}>
            <div
              className={styles.mainBtn}
              data-value="готово"
              onClick={() => onClose(value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Keyboard.propTypes = {
  isVip:    PropTypes.bool,
  keyBar:   PropTypes.bool,
  max:      PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  onClose:  PropTypes.func,
  userBets: PropTypes.object,
  value:    PropTypes.string,
}

Keyboard.defaultProps = {
  isVip:    false,
  keyBar:   true,
  max:      0,
  onChange: () => null,
  onClose:  () => null,
  userBets: {},
  value:    '',
}

const mapStateToProps = state => ({
  userBets: state.userBets,
})

export default connect(mapStateToProps, null)(Keyboard)
