import { Api } from 'api/Api'
import { STATUSES } from 'constants/statuses'
import { getCashOut } from 'store/cashout/actions'
import ACTIONS from 'store/constants/actions'
import {
  setIsFetching,
  updateBetData,
} from 'store/current_bet/actions'
import { getInfo } from 'store/user/actions'
import { setBetSuccess } from 'utils/google-analytics/EcommerceGTM'

const BET_TYPES = {
  LIVE:      'LIVE',
  LINE:      'LINE',
  ONE_CLICK: 'ONE_CLICK',
}

const BET_ENDPOINTS = {
  LIVE:      'makeBet',
  LINE:      'makeLineBet',
  ONE_CLICK: 'makeOneClickBet',
}

const COUPON_TYPES = {
  LIVE:      'live',
  LINE:      'line',
  ONE_CLICK: 'live',
}

const DEFAULT_MESSAGE = 'Не удалось сделать ставку'

const updateCoupon = ({ data, status, code }) => ({
  type: ACTIONS.COUPON.UPDATE,
  data,
  status,
  code,
})

export const getCoupon = (type, callback) => async dispatch => {
  try {
    const coupon = await Api.getCoupon(type)

    if (coupon.status === STATUSES.SUCCESS) {
      dispatch(updateCoupon(coupon))
      callback?.()

      if (type === 'live') {
        dispatch(getCashOut())
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export const getCouponLine = callback => async dispatch => {
  try {
    const coupon = await Api.getCouponLine()

    if (coupon.status === STATUSES.SUCCESS) {
      dispatch(updateCoupon(coupon))
      callback?.()
    }
  } catch (error) {
    console.error(error)
  }
}

export const addToCoupon = (type, eventId, hash) => async (dispatch, getState) => {
  try {
    const expressPlus = getState().line.express_plus
    const response    = await Api.couponAdd(eventId, hash, expressPlus)

    if (response.status === STATUSES.SUCCESS) {
      dispatch(updateCoupon(response))
    }
  } catch (err) {
    console.error(err)
  }
}

export const clearCoupon = type => async dispatch => {
  try {
    const { status } = await Api.couponClear(type)

    if (status === STATUSES.SUCCESS) {
      dispatch(updateCoupon({ data: {} }))
      dispatch(getCoupon(type))
    }
  } catch (error) {
    console.error(error)
  }
}

export const delCoupon = (type, eventId) => async dispatch => {
  try {
    const response = await Api.couponDel(type, eventId)

    if (response.status === STATUSES.SUCCESS) {
      dispatch(updateCoupon(response))
    }
  } catch (error) {
    console.error(error)
  }
}

export const setGoldBet = (type, gold, system = 0) => async dispatch => {
  await Api.setGoldBet(type, gold, system)
  dispatch(getCoupon(type))
}

export const setLiveOpt = option => async dispatch => {
  const { data } = await Api.setLiveOpt(option)
  dispatch({
    type: ACTIONS.COUPON.CHANGE_LIVE_OPT,
    data: data?.liveOpt,
  })
}

export const makeBet = (betData, type) => async dispatch => {
  dispatch(setIsFetching(true))

  try {
    if (type === BET_TYPES.ONE_CLICK) {
      await Api.couponClear(COUPON_TYPES.ONE_CLICK)

      const { status: couponStatus } = await Api.couponAdd(betData.e, betData.f)

      if (couponStatus === 'error') {
        dispatch(clearCoupon(COUPON_TYPES.ONE_CLICK))
      }
    }

    const { data, status, code, messages } = await Api[BET_ENDPOINTS[type]](betData)

    const isSuccess = status === STATUSES.SUCCESS

    if (isSuccess) {
      dispatch(updateCoupon({ data, status, code }))
      dispatch(updateBetData({ ...data.bet_data, error: false, time: Date.now() }))

      if (type === BET_TYPES.LIVE) {
        dispatch(getCoupon(COUPON_TYPES.LIVE))
      }

      dispatch(getInfo())
      setBetSuccess(data.bet_data?.bet_id, COUPON_TYPES[type])
    } else {
      dispatch(updateBetData({ message: messages?.join('. ') || DEFAULT_MESSAGE, error: true }))

      if (type === BET_TYPES.ONE_CLICK) {
        dispatch(clearCoupon(COUPON_TYPES.ONE_CLICK))
      }
    }

    return isSuccess
  } catch {
    dispatch({ type: ACTIONS.COUPON.ERROR })
    dispatch(updateBetData({ message: DEFAULT_MESSAGE, error: true }))

    if (type === BET_TYPES.ONE_CLICK) {
      dispatch(clearCoupon(COUPON_TYPES.ONE_CLICK))
    }

    return false
  } finally {
    dispatch(setIsFetching(false))
  }
}

export const setIsSettingsOpen = data => ({
  type: ACTIONS.COUPON.IS_SETTINGS_OPEN,
  data,
})

export const makeLiveBet     = data => makeBet(data, BET_TYPES.LIVE)
export const makeLineBet     = data => makeBet(data, BET_TYPES.LINE)
export const makeOneClickBet = data => makeBet(data, BET_TYPES.ONE_CLICK)
