import { debounce } from 'lodash'
import {
  useEffect,
  useRef,
} from 'react'
import { SafeResizeObserver } from 'utils/resize-observer'

const DEBOUNCE_DELAY = 120

export const useResizeObserver = (callback, deps = []) => {
  const ref = useRef()

  const debouncedCallback = debounce(callback, DEBOUNCE_DELAY)

  useEffect(() => {
    if (ref.current) {
      const resizeObserver = new SafeResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return
          }
          debouncedCallback(entries[0])
        })
      })

      resizeObserver.observe(ref.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, deps)

  return ref
}
