export const Select = ({ children, title, subTitle, onClick }) => (
  <div style={{ flex: 1 }}>
    <div
      className="casino-select"
      onClick={onClick}
    >
      <span className="casino-select__title">{title}</span>
      <span className="casino-select__sub-title">{subTitle}</span>
    </div>
    {children}
  </div>
)
