import animationData from 'assets/lottie/bet-processing.json'
import { Lottie } from 'components/Lottie'
import { toBYN } from 'pages/UserHistoryPage/utils'
import { useSelector } from 'react-redux'
import { isCouponProcessingSelector } from 'store/current_bet/selectors'
import styles from './styles.module.scss'

export const MakeBetButton = ({ onSubmit, possibleWin, disabled, booster, freeBet }) => {
  const isProcessing = useSelector(isCouponProcessingSelector)

  const { enabled, percent = 0 } = booster || {}

  const bonus    = possibleWin * percent / 100
  const hasBonus = !!bonus && !freeBet

  return (
    <div className={styles.makeBetBtn}>
      <button
        onClick={onSubmit}
        disabled={disabled}
        className={styles.btn}
      >
        {isProcessing ? (
          <Lottie
            src={animationData}
            className={styles.processingAnimation}
          />
        ) : (
          <>
            <div className={styles.title}>
              Сделать ставку
            </div>
            <div className={styles.possibleWin}>
              {`Возм. выигрыш: ${toBYN(possibleWin)} `}
              {hasBonus && (
                <span
                  className={styles.plusBonus}
                  data-disabled={!enabled}
                >
            {`+${toBYN(bonus)}`}
          </span>
              )}
            </div>
          </>
        )}
      </button>
    </div>
  )
}
