import { Api } from 'api/Api'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { TournamentsActionTypes } from 'store/tournaments/types'

const setTournaments = payload => ({
  type: TournamentsActionTypes.SET,
  payload,
})

export const getTournaments = type => async dispatch => {
  showPreloader()

  try {
    const { data = [] } = await Api.getTournaments(type)
    dispatch(setTournaments(data))
  } catch (e) {
    console.log(e.message)
  } finally {
    hidePreloader()
  }
}
