import { TournamentTableRow } from 'pages/Tournament/Table'
import styles from './styles.module.scss'

export const PersonalResult = props => (
  <table className={styles.personalResult}>
    <tbody>
    <TournamentTableRow {...props} />
    </tbody>
  </table>
)
