import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { reactiveStorage } from 'utils/reactive-storage'

export const QueryKey = {
  TOKEN:        'atoken',
  AFFILIATE_ID: 'affiliate_id',
  CLICK_ID:     'click_id',
  ML_SYS_ID:    'ml_sys_id',
}

export const StorageKey = {
  TOKEN: 'token',
}

export const useSearchParamsHandler = () => {
  const [sp, setSp] = useSearchParams()

  useEffect(() => {
    const token       = sp.get(QueryKey.TOKEN)
    const affiliateID = sp.get(QueryKey.AFFILIATE_ID)
    const clickID     = sp.get(QueryKey.CLICK_ID)
    const mlSysID     = sp.get(QueryKey.ML_SYS_ID)

    if (token) {
      reactiveStorage.setItem(StorageKey.TOKEN, token)
      sp.delete(QueryKey.TOKEN)
    }

    if (affiliateID) {
      reactiveStorage.setItem(QueryKey.AFFILIATE_ID, affiliateID)
    }

    if (clickID && mlSysID) {
      reactiveStorage.setItem(QueryKey.CLICK_ID, clickID)
      reactiveStorage.setItem(QueryKey.ML_SYS_ID, mlSysID)
      sp.delete(QueryKey.CLICK_ID)
      sp.delete(QueryKey.ML_SYS_ID)
    }

    if (clickID || mlSysID || token) {
      setSp(sp, { replace: true })
    }
  }, [])
}
