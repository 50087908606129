import styles from 'pages/Home/components/SportsFilter/styles.module.scss'
import {
  Interval,
  PageSection,
} from 'pages/Home/utils'
import {
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { connect } from 'react-redux'
import { setBetOnOur } from 'store/data/actions'
import { checkFavorites } from 'store/favorites/actions'
import { getFilters } from 'store/sports-filter/actions'
import { cn } from 'utils'
import { SportTab } from './SportTab'
import {
  CHAMP_PREFIX,
  getFavoritesIfExist,
  scrollToCenter,
} from './utils'

const SportsFilter = ({
  getFilters,
  liveFilters,
  lineFilters,
  period,
  favoritesCount,
  checkFavorites,
  isLiveBetOnOur,
  isLineBetOnOur,
  section,
  sportId,
  setBetOnOur,
}) => {
  const containerRef = useRef()

  const isLive     = section === PageSection.LIVE
  const isBetOnOur = isLive ? isLiveBetOnOur : isLineBetOnOur
  const filters    = isLive ? liveFilters : lineFilters

  useEffect(() => {
    if (favoritesCount) {
      const interval = setInterval(checkFavorites, Interval.LIVE)

      return () => {
        clearInterval(interval)
      }
    }
  }, [favoritesCount])

  const getSportFilters = useCallback(
    period => getFilters(isLive, period, isBetOnOur, sportId),
    [isLive, isBetOnOur, sportId],
  )

  useEffect(() => {
    getSportFilters(period)

    if (isLive) {
      const interval = setInterval(getSportFilters, Interval.LIVE)

      return () => {
        clearInterval(interval)
      }
    }
  }, [period, getSportFilters])

  const favorites = getFavoritesIfExist(favoritesCount)[section]

  useEffect(() => {
    scrollToCenter(containerRef)
  }, [section, sportId])

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div
          className={styles.tabsWrapper}
          ref={containerRef}
        >
          {[...favorites, ...filters].map(filter => {
            if (filter) {
              const { id, name, countEvents: count, championship: isChamp, className } = filter

              const slug = `${isChamp ? CHAMP_PREFIX : ''}${id}`
              const isBY = id === 'by'

              const props = {
                ...(isBY ? {
                  onClick: () => setBetOnOur({ [section]: !isBetOnOur }),
                } : {
                  to:           `/${section}/${slug}`,
                  championship: isChamp,
                  className:    cn(className, isChamp && slug, +count === 0 && !isLive && 'empty'),
                }),
              }

              return (
                <SportTab
                  key={id}
                  id={id}
                  name={name}
                  count={count}
                  active={isBY ? isBetOnOur : sportId === slug}
                  {...props}
                />
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  favoritesCount: state.favorites.ids.length,
  period:         state.sportsFilter.period,
  liveFilters:    state.sportsFilter.live,
  lineFilters:    state.sportsFilter.line,
  isLiveBetOnOur: state.data.betOnOur.live,
  isLineBetOnOur: state.data.betOnOur.line,
})

const mapDispatchToProps = {
  getFilters,
  checkFavorites,
  setBetOnOur,
}

export default connect(mapStateToProps, mapDispatchToProps)(SportsFilter)
