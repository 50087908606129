import { SEOCollapse } from 'components/SEOCollapse/index'
import { seoLineData } from 'components/SEOCollapse/Line/data'
import { cn } from 'utils'
import styles from '../styles.module.scss'

export const LineSEOCollapse = ({ sportId, className }) => {
  const { h1, content } = seoLineData[sportId] || {}

  return h1 ? (
    <SEOCollapse
      title={h1}
      className={cn(styles.homeSeoCollapse, className)}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </SEOCollapse>
  ) : null
}
