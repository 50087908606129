import { Api } from 'api/Api'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { paths } from 'constants/paths'
import { STATUSES } from 'constants/statuses'
import { useAuthModal } from 'hooks/useAuthModal'
import { useResizeObserver } from 'hooks/useResizeObserver'
import {
  prettifyHtml,
  TOP_STICKIES_HEIGHT,
} from 'pages/Tournament/Header/utils'
import {
  CASINO_TYPE,
  showSubscribeModal,
} from 'pages/Tournament/utils'
import {
  Fragment,
  useRef,
} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTournaments } from 'store/tournaments/actions'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const TournamentHeader = ({
  id,
  active,
  image,
  name,
  status,
  valid_from,
  valid_until,
  title,
  auth,
  disabled,
  type,
  onUpdate,
  sticky = true,
}) => {
  const dispatch      = useDispatch()
  const btnWrapperRef = useRef()
  const showAuthModal = useAuthModal()

  const stickyRef = useResizeObserver(({ borderBoxSize }) => {
    if (btnWrapperRef.current) {
      btnWrapperRef.current.style.top = `${borderBoxSize[0].blockSize + TOP_STICKIES_HEIGHT}px`
    }
  }, [])

  const isCasino    = type === CASINO_TYPE
  const hasButton   = isCasino || !disabled
  const stickyClass = sticky ? styles.sticky : ''

  const onSubmit = async () => {
    if (!auth) {
      return showAuthModal()
    }

    if (!active) {
      showPreloader()

      try {
        const { status, data } = await Api.activateTournament(id)

        const isOk = status === STATUSES.SUCCESS
        showSubscribeModal(isOk, data?.messages || data)

        if (isOk) {
          onUpdate?.()
          await dispatch(getTournaments())
        }
      } catch {
        showSubscribeModal()
      } finally {
        hidePreloader()
      }
    }
  }

  return (
    <>
      <div className={styles.imgWrapper}>
        <img
          className={styles.img}
          src={image}
          alt={name}
        />
      </div>
      <div className={cn(styles.startedAtWrapper, stickyClass)}>
        <div className={styles.startedAt}>
          {status}
        </div>
      </div>
      <div className={styles.duration}>
        {`с ${valid_from} до ${valid_until}`}
      </div>
      <div
        className={cn(styles.title, stickyClass)}
        {...sticky && { ref: stickyRef }}
      >
        {name}
      </div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: prettifyHtml(title) }}
      />
      {hasButton && (
        <div
          className={cn(styles.btnWrapper, stickyClass, !isCasino && active && styles.active)}
          {...sticky && { ref: btnWrapperRef }}
        >
          {isCasino ? (
            <Link
              to={paths.CASINO_SLOTS}
              className={styles.btn}
            >
              К игре
            </Link>
          ) : (
            <button
              className={styles.btn}
              onClick={onSubmit}
            >
              {active ? 'Вы участвуете' : 'Участвовать'}
            </button>
          )}
        </div>
      )}
    </>
  )
}
