import { useAuth } from 'hooks/useAuth'
import { useAuthModal } from 'hooks/useAuthModal'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { toggleFavorites } from 'store/casino/actions'
import { selectFavorites } from 'store/casino/selectors'

export const useFavorite = id => {
  const dispatch      = useDispatch()
  const favorites     = useSelector(selectFavorites)
  const isAuth        = useAuth()
  const openAuthModal = useAuthModal()

  const isActive       = favorites.includes(+id)
  const toggleFavorite = () => isAuth ? dispatch(toggleFavorites(id)) : openAuthModal()

  return { isActive, toggleFavorite }
}
