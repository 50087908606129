const ACTIONS = {
  LIVE:                 {
    GET_BY_SPORT:  'LIVE_GET_BY_SPORT',
    SET_TOP_COUNT: 'SET_TOP_COUNT',
    TOP:           {
      GET:              'LIVE_TOP_GET',
      CLEAR:            'LIVE_TOP_CLEAR',
      SET_FETCHING:     'LIVE_TOP_SET_FETCHING',
      SET_SLIDES:       'LIVE_SET_SLIDES',
      SET_CASINO_GAMES: 'LIVE_SET_CASINO_GAMES',
    },
    VIDEO:         {
      GET:          'LIVE_VIDEO_GET',
      CLEAR:        'LIVE_VIDEO_CLEAR',
      SET_FETCHING: 'LIVE_VIDEO_SET_FETCHING',
    },
  },
  LIVE_SOON:            {
    GET_LIST:         'LIVE_SOON_GET_LIST',
    CACHED_DATA:      'LIVE_SOON_CACHED_DATA',
    CACHE_FETCHING:   'LIVE_SOON_CACHE_FETCHING',
    SET_ACTIVE_SPORT: 'SET_ACTIVE_SPORT',
  },
  SPORTS_FILTER:        {
    UPDATE_LIVE:    'SPORTS_FILTER_UPDATE_LIVE',
    UPDATE_LINE:    'SPORTS_FILTER_UPDATE_LINE',
    UPDATE_PERIOD:  'SPORTS_FILTER_UPDATE_PERIOD',
    SET_LIVE_COUNT: 'SPORTS_FILTER_SET_LIVE_COUNT',
    SET_TOP_COUNT:  'SPORTS_FILTER_SET_TOP_COUNT',
  },
  LINE:                 {
    GET_SETTINGS:        'LINE_GET_SETTINGS',
    GET_LINE:            'LINE_GET_LINE',
    GET_LEAGUES:         'LINE_GET_LEAGUES',
    GET_POPULAR:         'LINE_GET_POPULAR',
    TOGGLE_EXPRESS_PLUS: 'LINE_TOGGLE_EXPRESS_PLUS',
    GET_TOP:             'LINE_GET_TOP',
    GET_SPORT_LEAGUES:   'LINE_GET_SPORT_LEAGUES',
    RESET_POPULAR:       'LINE_RESET_POPULAR',
    FETCHING:            'LINE_FETCHING',
    LEAGUES_BY_ID:       'LINE_LEAGUES_BY_ID',
    LEAGUES_FETCHING:    'LINE_LEAGUES_FETCHING',
    SET_SLIDES:          'LINE_LEAGUES_SET_SLIDES',
  },
  RESULT:               {
    GET_LIVE:                   'RESULT_GET_LIVE',
    GET_LINE:                   'RESULT_GET_LINE',
    FETCHING:                   'RESULT_FETCHING',
    RESET_FILTER:               'RESET_FILTER',
    RESET_ALL_SELECTED_FILTERS: 'RESET_ALL_SELECTED_FILTERS',
    RESET_ALL_CHECKED_FILTERS:  'RESET_ALL_CHANGED_FILTERS',
    CHANGE_CHECKED_SPORT:       'CHANGE_CHECKED_SPORT',
    CHANGE_SELECTED_SPORT:      'CHANGE_SELECTED_SPORT',
    CHANGE_CHECKED_DATA:        'CHANGE_CHECKED_DATA',
    CHANGE_SELECTED_DATA:       'CHANGE_SELECTED_DATA',
    INIT_STATE:                 'INIT_STATE',
  },
  RESULT_FROM_MARAPHON: {
    GET_SPORTS_LINE:            'RESULT_FROM_MARAPHON_GET_SPORTS_LIVE',
    GET_LINE:                   'RESULT_FROM_MARAPHON_GET_LINE',
    FETCHING:                   'RESULT_FROM_MARAPHON_FETCHING',
    RESET_FILTER:               'RESET_FROM_MARAPHON_FILTER',
    RESET_ALL_SELECTED_FILTERS: 'RESET_FROM_MARAPHON_ALL_SELECTED_FILTERS',
    CHANGE_CHECKED_SPORT:       'CHANGE_CHECKED_SPORT',
    CHANGE_SELECTED_SPORT:      'CHANGE_SELECTED_SPORT',
    CHANGE_CHECKED_DATA:        'CHANGE_CHECKED_DATA',
    CHANGE_SELECTED_DATE:       'CHANGE_SELECTED_DATE',
    INIT_STATE:                 'INIT_STATE',
    CHANGE_NO_ONE_SPORT:        'RESULT_FROM_MARAPHON_CHANGE_NO_ONE_SPORT',
  },
  USER:                 {
    LOGIN:                 'USER_LOGIN',
    LOGOUT:                'USER_LOGOUT',
    GET_INFO:              'USER_GET_INFO',
    SET_ORDERED_PAYMENT:   'USER_SET_ORDERED_PAYMENT',
    SAVE_INFO:             'SAVE_INFO',
    CHANGE_CHECKED:        'CHANGE_CHECKED',
    UPLOAD_PASSPORT:       'UPLOAD_PASSPORT',
    GET_PERSONAL_DATA:     'GET_PERSONAL_DATA',
    SET_PROMO_CODES_COUNT: 'SET_PROMO_CODES_COUNT',
  },
  COUPON:               {
    FETCHING:             'COUPON_FETCHING',
    UPDATE:               'COUPON_UPDATE',
    CHANGE_LIVE_OPT:      'COUPON_CHANGE_LIVE_OPT',
    ERROR:                'COUPON_ERROR',
    GET_OPENED_BETS_LIVE: 'COUPON_GET_OPENED_BETS_LIVE',
    GET_OPENED_BETS_LINE: 'COUPON_GET_OPENED_BETS_LINE',
    RESET:                'COUPON_RESET',
    IS_SETTINGS_OPEN:     'COUPON_IS_SETTINGS_OPEN',
  },
  BET_DATA:             {
    UPDATE:      'BET_DATA_UPDATE',
    CLEAR:       'BET_DATA_CLEAR',
    SET_IS_OPEN: 'BET_DATA_SET_IS_OPEN',
    LOADING:     'BET_DATA_LOADING',
  },
  DATA:                 {
    COUPON_FACTORS: 'DATA_COUPON_FACTORS',
  },
  VIDEO_MODAL:          {
    UPDATE: 'VIDEO_MODAL_UPDATE',
  },
  AUTH:                 {
    UPDATE:         'AUTH_UPDATE',
    IS_ONLINE:      'AUTH_IS_ONLINE',
    AUTH_HISTORY:   'AUTH_HISTORY',
    AUTH_DATE_FROM: 'AUTH_DATE_FROM',
    AUTH_DATE_TO:   'AUTH_DATE_TO',
    LOGOUT:         'AUTH_LOGOUT',
  },
  HISTORY_BETS:         {
    ADD_LOADING:    'HISTORY_BETS/ADD_LOADING',
    ADD_ERROR:      'HISTORY_BETS/ADD_ERROR',
    GET_RESULTS:    'HISTORY_BETS/GET_RESULTS',
    ADD_SCORES:     'HISTORY_BETS/ADD_SCORES',
    SET_ITEM:       'HISTORY_BETS/SET_ITEM',
    GET_PAY_RETURN: 'HISTORY_BETS/GET_PAY_RETURN',
  },
  CASHOUT:              {
    GET:           'CASHOUT_GET',
    GET_CARD_LIST: 'GET_CARD_LIST',
    CARD_OUT:      'CARD_OUT',
    FETCHING:      'CASHOUT_FETCHING',
    SUCCESS:       'CASHOUT_SUCCESS',
    FAILURE:       'CASHOUT_FAILURE',
    CLEAR:         'CLEAR_DONE',
  },
  SYSTEM:               {
    CHANGE_THEME:                'SYSTEM_CHANGE_THEME',
    CHANGE_BALANCE:              'CHANGE_BALANCE',
    CHANGE_DISPLAY_STYLE_KOEFF:  'CHANGE_DISPLAY_STYLE_KOEFF',
    CHANGE_OUTCOMES:             'CHANGE_OUTCOMES',
    CHANGE_CASH_OUT:             'CHANGE_CASH_OUT',
    GET_OUTCOMES:                'GET_OUTCOMES',
    HIDE_ADDITIONAL_LIVE:        'HIDE_ADDITIONAL_LIVE',
    CHANGE_AUTO_CONFIRM_CASHOUT: 'CHANGE_AUTO_CONFIRM_CASHOUT',
    CHANGE_SESSION_LIFE_TIME:    'CHANGE_SESSION_LIFE_TIME',
    CHANGE_CALCULATED_BET_TIME:  'CHANGE_CALCULATED_BET_TIME',
    GET_CARDS:                   'GET_CARDS',
    HIDE_CARD:                   'HIDE_CARD',
    SHOW_CARD:                   'SHOW_CARD',
    RESET_CHANGE_KOEFF:          'RESET_CHANGE_KOEFF',
    INITIALIZED:                 'INITIALIZED',
    SET_RANDOM_BONUS:            'SET_RANDOM_BONUS',
    SET_WAGER_BONUS:             'SET_WAGER_BONUS',
    SET_WAGER_CONDITIONS:        'SET_WAGER_CONDITIONS',
    SET_IS_CASINO_ENABLED:       'SET_CASINO_ENABLED',
    SET_BET_ON_OUR:              'SET_BET_ON_OUR',
    SET_DEVICE:                  'SET_DEVICE',
    NOT_CALC_BETS:               'NOT_CALC_BETS',
    TOURNAMENTS_COUNT:           'TOURNAMENTS_COUNT',
  },
  SERVICE:              {
    SET_STATUS:         '[SERVICE]:SET_STATUS',
    SET_PAYMENT_LIMITS: '[SERVICE]:SET_PAYMENT_LIMITS',
  },
  NEWS:                 {
    GET_NEWS_SUCCESS: 'RESULT_GET_NEWS_SUCCESS',
    GET_UNREAD:       'NEWS_GET_UNREAD',
    UPDATE_DATE:      'NEWS_UPDATE_DATE',
  },
  BONUSES:              {
    GET_BONUSES:        'RESULT_GET_BONUSES',
    GET_CASHBACK_BONUS: 'GET_CASHBACK_BONUS',
  },
  BONUS_HUNDRED:        {
    GET: 'GET_BONUS_HUNDRED',
  },
  WHATS_NEW:            {
    GET_WHATS_NEW: 'RESULT_GET_WHATS_NEW',
  },
  CASHBACK:             {
    GET_CASHBACK: 'RESULT_GET_CASHBACK',
    PAY_CASHBACK: 'RESULT_PAY_CASHBACK',
  },
  FORECAST:             {
    GET:         {
      REQUEST: 'FORECAST_GET_REQUEST',
      SUCCESS: 'FORECAST_GET_SUCCESS',
      FAILURE: 'FORECAST_GET_FAILURE',
    },
    GET_WINNERS: {
      REQUEST: 'FORECAST_GET_WINNERS_REQUEST',
      SUCCESS: 'FORECAST_GET_WINNERS_SUCCESS',
      FAILURE: 'FORECAST_GET_WINNERS_FAILURE',
    },
    CHANGE:      {
      SELECTED: 'FORECAST_CHANGE_SELECTED',
      RANDOM:   'FORECAST_CHANGE_RANDOM',
      LOADING:  'FORECAST_CHANGE_LOADING',
    },
    CLEAR:       'FORECAST_CLEAR',
  },
  HISTORY_FORECAST:     {
    GET: 'FORECAST_GET_HISTORY',
  },
  SEARCH_EVENT:         {
    GET_SEARCH_EVENT:       'RESULT_GET_SEARCH_EVENT',
    GET_SEARCH_EVENT_ERROR: 'RESULT_GET_SEARCH_EVENT_ERROR',
    CLEAR_SEARCH_MESSAGES:  'CLEAR_SEARCH_MESSAGES',
  },
  PPS:                  {
    GET_LIST: 'PPS_GET_LIST',
  },
  MODAL:                {
    ADD:   'MODAL_ADD',
    CLOSE: 'MODAL_CLOSE',
  },
  BANKS:                {
    SET: 'BANKS_SET',
  },
  ONE_CLICK_BETS:       {
    AMOUNT_UPDATE:    'ONE_CLICK_BETS-AMOUNT_UPDATE',
    IS_ACTIVE_UPDATE: 'ONE_CLICK_BETS-AMOUNT_IS_ACTIVE_UPDATE',
  },
  MARGIN_0:             {
    SET: 'MARGIN_0_SET',
  },
  CHAMPIONSHIP:         {
    GROUPS:     'CHAMPIONSHIP_GROUPS',
    FETCHING:   'CHAMPIONSHIP_FETCHING',
    CATEGORIES: 'CHAMPIONSHIP_CATEGORIES',
    CURRENT:    'CHAMPIONSHIP_CURRENT',
  },
  USER_BETS:            {
    GET:      'USER_BETS-GET',
    FETCHING: 'USER_BETS-FETCHING',
    ERROR:    'USER_BETS-ERROR',
  },
  LEFT_PANEL:           {
    GET_LIVE_COUNT: 'GET_LIVE_COUNT',
  },
  COLLECTIONS:          {
    FETCHING:        'COLLECTIONS_FETCHING',
    GET_COLLECTIONS: 'GET_COLLECTIONS',
    GET_LEAGUES:     'GET_COLLECTIONS_LEAGUES',
  },
  MESSAGES:             {
    FETCHING:       'MESSAGES_FETCHING',
    GET_LIST:       'MESSAGES_GET_LIST',
    GET_UNREAD:     'MESSAGES_GET_UNREAD',
    GET_CATEGORIES: 'MESSAGES_GET_ALL_CATEGORIES',
  },
  TOP_EXPRESS:          {
    GET_LIST:   'TOP_EXPRESS_GET_LIST',
    SET_COUPON: 'TOP_EXPRESS_SET_COUPON',
  },
  PROMO_CODES:          {
    GET_PROMO_CODES: 'PROMO_CODES_GET_PROMO_CODES',
    CLEAR:           'PROMO_CODES_CLEAR',
  },
  OPLATI:               {
    CHANGE_TAB:   'OPLATI_CHANGE_TAB',
    CHANGE_MODAL: 'OPLATI_CHANGE_MODAL',
  },
  CASINO:               {
    SET_SLIDES:            'CASINO_SET_SLIDES',
    SET_PROVIDERS:         'CASINO_SET_PROVIDERS',
    SET_CATEGORIES:        'CASINO_SET_CATEGORIES',
    SET_THEMES:            'CASINO_SET_THEMES',
    SET_GAMES:             'CASINO_SET_GAMES',
    SET_RECENT_GAMES:      'CASINO_SET_RECENT_GAMES',
    SET_GAMES_LIST:        'CASINO_SET_GAMES_LIST',
    SET_FAVORITES:         'CASINO_SET_FAVORITES',
    SET_PROVIDERS_BY_KEYS: 'CASINO_SET_PROVIDERS_BY_KEYS',
    SET_IS_FAVORITE_PAGE:  'CASINO_SET_IS_FAVORITE_PAGE',
    SET_COUNTERS:          'CASINO_SET_COUNTERS',
    CHANGE_LOADING:        'CASINO_CHANGE_LOADING',
    CHANGE_GAMES_SEARCH:   'CASINO_CHANGE_GAMES_SEARCH',
    CHANGE_ACTIVE_IDS:     'CASINO_CHANGE_ACTIVE_IDS',
    CHANGE_OPEN_SELECTS:   'CASINO_CHANGE_OPEN_SELECTS',
    SET_QUICK_GAMES:       'SET_QUICK_GAMES',
    SET_PAGE_ID:           'CASINO_SET_PAGE_ID',
    CLEAR_DATA:            'CASINO_CLEAR_DATA',
    CLEAR_STATE:           'CASINO_CLEAR_STATE',
    SET_POPULARS:          'CASINO_SET_POPULARS',
    SET_MAXLINE_PAY:       'CASINO_SET_MAXLINE_PAY',
    APP_ONLY_GAMES_IDS:    'CASINO_APP_ONLY_GAMES_IDS',
  },
  HIGHLIGHTS:           {
    GET_HIGHLIGHTS: 'HIGHLIGHTS_GET_HIGHLIGHTS',
    CHANGE_LOADING: 'HIGHLIGHTS_CHANGE_LOADING',
    CLEAR:          'HIGHLIGHTS_CLEAR',
  },
}

export default ACTIONS
