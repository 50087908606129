import { CentrifugoProvider } from 'components/Centrifugo'
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { router } from 'router'
import { configureStore } from 'store'
import 'assets/css/main.scss'
import { postInitMessage } from 'utils'

postInitMessage()

const { store, dispatch, persistor } = configureStore()

export const AppProvider = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <CentrifugoProvider>
        <Suspense fallback="Загрузка...">
          <RouterProvider router={router} />
        </Suspense>
      </CentrifugoProvider>
    </PersistGate>
  </Provider>
)

export { dispatch }
