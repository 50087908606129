import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'

export const CASINO_TYPE = 3

export const initialState = {
  result:    {},
  rows:      [],
  your:      null,
  isFetched: false,
}

export const TOURNAMENT_INTERVAL = 60 * 1000

export const showSubscribeModal = (success, messages) => {
  const showModal = success ? showSuccessModal : showErrorModal

  showModal({
    title:     messages?.toString() ?? 'Пожалуйста, попробуйте позже.',
    submitBtn: { title: success ? 'Ок' : 'Назад' },
  })
}
