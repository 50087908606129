import { PersonalResult } from 'pages/Tournament/PersonalResult'
import { TournamentTable } from 'pages/Tournament/Table'
import styles from './styles.module.scss'

export const TournamentResults = ({ your, rows, className, children }) => (
  <div className={className}>
    {children}
    {your && <PersonalResult {...your} />}
    <h2 className={styles.heading}>Турнирная таблица</h2>
    <TournamentTable rows={rows} />
  </div>
)
