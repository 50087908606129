import { Place } from 'pages/Tournament/Table/Place'
import { UserBets } from 'pages/Tournament/Table/UserBets'
import styles from './styles.module.scss'

export const TournamentTableRow = ({ is_prize, member, scores, i, data }) => (
  <tr data-place={i}>
    <td className={styles.index}>
      <Place
        value={i}
        prize={is_prize}
      />
    </td>
    <td className={styles.member}>
      <div>{member.name}</div>
      <div>{member.id}</div>
    </td>
    <td className={styles.right}>
      <div className={styles.scoresWrapper}>
        <div className={styles.scores}>
          <div>{scores.value}</div>
          <div>{scores.prize}</div>
        </div>
        {data?.items && <UserBets user={member} {...data} />}
      </div>
    </td>
  </tr>
)

export const TournamentTable = ({ rows }) => (
  <table className={styles.table}>
    <thead>
    <tr className={styles.header}>
      <td>№</td>
      <td>Участник</td>
      <td>Баллы/Приз</td>
    </tr>
    </thead>
    <tbody className={styles.body}>
    {rows.map(row => <TournamentTableRow key={row.i} {...row} />)}
    </tbody>
  </table>
)
