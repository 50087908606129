import ACTIONS from 'store/constants/actions'

const initialState = {
  loading: false,
  data:    {
    error: false,
  },
}

export const currentBetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.BET_DATA.UPDATE: {
      return { ...state, data: action.payload }
    }

    case ACTIONS.BET_DATA.LOADING: {
      return { ...state, loading: action.payload }
    }

    case ACTIONS.BET_DATA.CLEAR: {
      return initialState
    }

    default:
      return state
  }
}
