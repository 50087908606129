import { StorageKey } from 'hooks/useSearchParamsHandler'
import { useWillUnmount } from 'hooks/useWillUnmount'
import CasinoGamePage from 'pages/Casino/Game'
import { GameParam } from 'pages/Casino/Game/utils'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Outlet,
  useSearchParams,
} from 'react-router-dom'
import { resetCasinoState } from 'store/casino/creators'
import { reactiveStorage } from 'utils/reactive-storage'
import { CasinoWS } from 'utils/socket/socket'

export const CasinoLayout = () => {
  const dispatch       = useDispatch()
  const [searchParams] = useSearchParams()

  const gameID = searchParams.get(GameParam.ID)

  useEffect(() => {
    const ws = new CasinoWS()
    reactiveStorage.observe(StorageKey.TOKEN, ws.reconnect)

    return () => {
      ws.destroy()
      reactiveStorage.unobserve(StorageKey.TOKEN, ws.reconnect)
    }
  }, [])

  useWillUnmount(() => dispatch(resetCasinoState()))

  return (
    <>
      {gameID && <CasinoGamePage id={gameID} />}
      <Outlet />
    </>
  )
}
