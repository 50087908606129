import SportIcon from 'components/SportIcon'
import { getSportColor } from 'pages/Event/EventById/utils'
import {
  Children,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const SportDivider = ({ sport, children, link, label }) => {
  const [isOpen, setIsOpen] = useState(true)

  const shouldRender = !!Children.count(children)

  return (
    shouldRender ?
      <div>
        <div
          style={{ backgroundColor: getSportColor(sport) }}
          onClick={() => setIsOpen(!isOpen)}
          className={cn(styles.sportDivider, isOpen && styles.open)}
        >
          <SportIcon id={+sport.id} />
          <div className={styles.sportName}>
            {sport.name}
          </div>
          {label && <span className={styles.label}>{label}</span>}
          {link && (
            <Link
              to={`/live/${sport.id}`}
              className={styles.link}
              onClick={event => event.stopPropagation()}
            >
              Показать Bсе
            </Link>
          )}
        </div>
        {isOpen && (
          <div className={styles.children}>
            {children}
          </div>
        )}
      </div> : null
  )
}
