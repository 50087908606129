import { Backdrop } from 'components/Backdrop'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const SignUpModalWrapper = ({
  fullscreen = true,
  closeBtn = true,
  onClose,
  className,
  children,
}) => (
  <>
    <div className={cn(styles.signUpModalWrapper, fullscreen && styles.fullscreen, className)}>
      {closeBtn && (
        <button
          className={styles.closeBtn}
          onClick={onClose}
          aria-label="Close button"
        />
      )}
      {children}
    </div>
    {!fullscreen && (
      <Backdrop
        onClose={onClose}
        zIndex={210}
      />
    )}
  </>
)
