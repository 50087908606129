import { Game } from 'components/Casino/Slots/Game'
import { tagsToObject } from 'components/Casino/Slots/utils'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import styles from './styles.module.scss'

const TopHeatedGames = ({ list, tags, rtpValues }) => {
  const tagsObj = useMemo(() => tagsToObject(tags), [tags.length])

  return (
    <div className={styles.topHeatedGames}>
      {list.map(({ id, tags: tagIds, ...restProps }, idx) => (
        <div
          key={id}
          className={styles[`place${idx + 1}`]}
        >
          <Game
            id={id}
            tags={tagIds.map(id => tagsObj[id])}
            rtpValues={rtpValues}
            {...restProps}
          />
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = state => ({
  tags:      state.casino.gamesInfo.tags || [],
  rtpValues: state.casino.gamesInfo.rtpValues,
})

export default connect(mapStateToProps)(TopHeatedGames)
