import ACTIONS from 'store/constants/actions'

const initialState = {
  sports:     [],
  events:     [],
  factors:    {},
  results:    {},
  clubs:      [],
  slides:     [],
  games:      [],
  tagsObj:    {},
  rtpValues:  {},
  isFetching: false,
}

export const liveTop = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTIONS.LIVE.TOP.GET: {
      return { ...state, ...payload }
    }

    case ACTIONS.LIVE.TOP.CLEAR: {
      return initialState
    }

    case ACTIONS.LIVE.TOP.SET_FETCHING: {
      return { ...state, isFetching: payload }
    }

    case ACTIONS.LIVE.TOP.SET_SLIDES: {
      return { ...state, slides: payload }
    }

    default:
      return state
  }
}
