import { useIsScrolling } from 'hooks/useIsScrolling'
import {
  useEffect,
  useState,
} from 'react'

export const BetStatus = {
  DELETED: 1,
  DOWN:    2,
  UP:      3,
  STOPPED: 4,
}

export const getIsBetOutdated = status => +status === BetStatus.DELETED || +status === BetStatus.STOPPED

export const useCouponKeyboard = id => {
  const [hasKeyboard, setHasKeyboard] = useState(false)

  useEffect(() => {
    if (hasKeyboard) {
      const onClick = ({ target }) => {
        const moneyInputID = target.closest('[class*=moneyInput]')?.dataset?.betInputId

        if (moneyInputID && moneyInputID !== id) {
          setHasKeyboard(false)
        }
      }

      document.addEventListener('click', onClick)

      return () => {
        document.removeEventListener('click', onClick)
      }
    }
  }, [hasKeyboard])

  return { hasKeyboard, setHasKeyboard }
}

export const useCloseOnScroll = (condition, onClose) => {
  const isScrolling = useIsScrolling(window, condition)

  useEffect(() => {
    if (isScrolling) {
      onClose()
    }
  }, [isScrolling])
}
