import { BetInput } from 'components/Coupon/BetInput'
import { BetLink } from 'components/Coupon/BetLink'
import { COUPON_KEYBOARD_ID } from 'components/Coupon/Line/utils'
import { StartedAt } from 'components/Coupon/StartedAt'
import { useCouponKeyboard } from 'components/Coupon/utils'
import Keyboard from 'components/Keyboard'
import { Portal } from 'components/Portal'
import { SwipeToDelete } from 'components/SwipeToDelete'
import { toBYN } from 'pages/UserHistoryPage/utils'
import {
  Fragment,
  useEffect,
} from 'react'
import TEXT from 'store/constants/text'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const Bet = ({
  id,
  started,
  title,
  time,
  description,
  coefficient,
  max,
  onDelete,
  value,
  onChange,
  className,
  isVip,
  single,
  onClose,
}) => {
  const { setHasKeyboard, hasKeyboard } = useCouponKeyboard(id)

  useEffect(() => {
    if (single) {
      setHasKeyboard(true)
    }
  }, [single])

  const startedClasses = cn(started && 'removed')

  const Wrapper = onDelete ? SwipeToDelete : Fragment

  return (
    <Wrapper {...(onDelete && { onDelete })}>
      <div className={cn(styles.betLine, className)}>
        <div className={styles.header}>
          <div className={cn(styles.title, startedClasses)}>
            {title.replace(/\.$/, '')}
          </div>
          <div className={styles.coeff}>
            <div className={cn(styles.value, startedClasses)}>
              {coefficient}
            </div>
            {onDelete && (
              <button
                className={styles.deleteBtn}
                onClick={onDelete}
              />
            )}
          </div>
        </div>
        <BetLink
          id={id}
          value={description}
          onClose={onClose}
          disabled={started}
        />
        <StartedAt dirtyTime={time} />
        {onChange && (
          <>
            <div className="coupon__line-single-footer">
              <BetInput
                id={id}
                value={value}
                max={max}
                isOpen={hasKeyboard}
                isVip={isVip}
                onOpen={() => setHasKeyboard(true)}
                onChange={onChange}
              />
              <div className="ml-15">
                <div
                  className="gray"
                  {...(max && { onClick: () => onChange(max) })}
                >
                  <span>{`${TEXT.coupon.max}: `}</span>
                  <span className="green underline">{toBYN(max)}</span>
                </div>
                {!single && (
                  <div className="gray">
                    <span>{`${TEXT.coupon.possibleWinShort}: `}</span>
                    <span>{toBYN(coefficient * value)}</span>
                  </div>
                )}
              </div>
            </div>
            {hasKeyboard &&
              <Portal selector={`#${COUPON_KEYBOARD_ID}`}>
                <Keyboard
                  max={max}
                  value={value}
                  onChange={onChange}
                  onClose={() => setHasKeyboard(false)}
                  isVip={isVip}
                />
              </Portal>}
          </>
        )}
      </div>
    </Wrapper>
  )
}
