import { Backdrop } from 'components/Backdrop'
import {
  Landscape,
  Portrait,
} from 'components/Orientation'
import { Bonus22 } from 'components/PaymentModal/Bonus22'
import { PaymentTabs } from 'components/PaymentModal/Tabs'
import { PaymentTab } from 'components/PaymentModal/utils'
import { Portal } from 'components/Portal'
import { usePaymentsState } from 'hooks/usePaymentsState'
import { useStopScroll } from 'hooks/useStopScroll'
import { useEffect } from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const PaymentModal = () => {
  const {
          state: { isVisible, method, is_blocked_casino, is_blocked_sport },
          closeModal,
          getDetails,
          reset,
        } = usePaymentsState()

  useStopScroll(isVisible)

  useEffect(() => {
    getDetails()

    return reset
  }, [])

  const SelectedTab        = PaymentTab[method]
  const isBonus22Available = !is_blocked_casino || !is_blocked_sport

  return isVisible ? (
    <Portal>
      <Backdrop zIndex={200} />
      <div className={styles.paymentModal}>
        <button
          onClick={closeModal}
          className={styles.closeBtn}
          aria-label="Close"
        />
        <Landscape className={cn(styles.landscape, !isBonus22Available && styles.withOutBonus22)}>
          <PaymentTabs />
          <div className={styles.divider} />
          <Bonus22 />
          <div className={styles.divider} />
          <SelectedTab />
        </Landscape>
        <Portrait className={styles.portrait}>
          <div>
            <h2 className={styles.title}>Пополнить счёт</h2>
            <PaymentTabs />
          </div>
          <Bonus22 />
          <SelectedTab />
        </Portrait>
      </div>
    </Portal>
  ) : null
}