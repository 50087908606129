import { ModalType } from 'components/SignInModal/utils'
import { getTabs } from 'components/TabBar/utils'
import { useAuth } from 'hooks/useAuth'
import { useAuthModal } from 'hooks/useAuthModal'
import { useSelector } from 'react-redux'
import {
  Link,
  useLocation,
} from 'react-router-dom'
import { selectNotCalcBets } from 'store/data/selectors'
import styles from './styles.module.scss'

export const TabBar = () => {
  const { pathname }  = useLocation()
  const isAuth        = useAuth()
  const openAuthModal = useAuthModal()

  const count = useSelector(selectNotCalcBets)

  const onClick = (event, redirectURL, needAuth) => {
    if (needAuth && !isAuth) {
      event.preventDefault()
      openAuthModal(ModalType.UNAUTHORIZED, { redirectURL })
    }
  }

  return (
    <div
      id="tab-bar"
      className={styles.tabBar}
    >
      <div className={styles.wrapper}>
        {getTabs(pathname, count).map(({ title, link, icon: Icon, needAuth, active, badge }) => (
          <Link
            key={link}
            to={link}
            className={styles.tabBtn}
            data-active={active}
            onClick={event => onClick(event, link, needAuth)}
          >
            {!!badge && <div className={styles.badge}>{badge}</div>}
            <Icon className={styles.icon} />
            <span className={styles.title}>{title}</span>
          </Link>
        ))}
      </div>
    </div>
  )
}
