const COUPON_KEY = 'coupon'
const dataFromLs = localStorage.getItem(COUPON_KEY)

export const emptyState = {
  coupon:         {},
  isset:          {},
  isSettingsOpen: false,
}

export const initialState = dataFromLs ? JSON.parse(dataFromLs) : emptyState

export const setCoupon = coupon => localStorage.setItem(COUPON_KEY, JSON.stringify(coupon))

export const getCouponIsset = couponItems => {
  const result = {}

  couponItems?.forEach(item => {
    if (!result.hasOwnProperty(item.idEvent)) {
      result[item.idEvent] = {}
    }

    if (!result[item.idEvent].hasOwnProperty(item.f)) {
      result[item.idEvent][item.f] = true
    }
  })

  return result
}
