import { showSuccessModal } from 'components/Popup'
import { paths } from 'constants/paths'

export const onSuccessPayout = () => showSuccessModal({
  title:     'Заявка принята',
  message:   'Ваш запрос на выплату был принят, статус заявки будет отображен в истории платежей',
  cancelBtn: {
    title: 'История платежей',
    to:    paths.PAYMENTS_HISTORY,
  },
})
