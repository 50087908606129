export const PERIOD_NONE  = -1
export const BY_FILTER_ID = -3

export const sportNames = {
  117: 'Кибер-футбол',
  118: 'Кибер-баскетбол',
  119: 'Кибер-хоккей',
}

export const allSports = { id: 'all-sports', name: 'Все' }

const collator = new Intl.Collator('en')

export const sortSports = (filters, isLive) => {
  const sortBy = `order_${isLive ? 'live' : 'line'}`

  return filters.sort((a, b) => collator.compare(a.name, b.name)).sort((a, b) => +b[sortBy] - +a[sortBy])
}

export const updateLiveSports = (sports, counter) => sortSports(sports, true)
  .map(sport => ({ ...sport, name: sportNames[+sport.id] || sport.name, countEvents: counter[sport.id] }))
  .filter(sport => +sport.id >= 0)

export const updateLineSports = (sports, period) => {
  const withEvents    = []
  const withoutEvents = []

  sports.forEach(({ id, name, order_line, periods = {}, countEvents }) => {
      const countEventsByPeriod = !!period ? periods?.[period] : countEvents
      return ((countEventsByPeriod ? withEvents : withoutEvents).push({
        id,
        name,
        order_line,
        countEvents: countEventsByPeriod || '0',
      }))
    },
  )
  return [...sortSports(withEvents), ...sortSports(withoutEvents)]
}

const getBYFilter  = count => ({ id: 'by', name: 'Беларусь', countEvents: count || '0' })
const getTVFilter  = count => ({ id: 'video', name: 'Трансляции', countEvents: count || '0' })
const getTopFilter = count => ({ id: 'top', name: 'ТОП', countEvents: count || '0' })

export const buildSportFilters = (rawSports, championships, period) => {
  const first = []
  const rest  = []

  rawSports.forEach(sport => +sport.id === BY_FILTER_ID
    ? first.push(getBYFilter(period ? sport.periods[period] : sport.countEvents))
    : rest.push(sport))

  const champs = championships.map(({ id, name }) => ({ championship: true, id, name }))

  return [
    ...first,
    allSports,
    ...champs,
    ...updateLineSports(rest, period),
  ]
}

export const getStaticFilters = counter => {
  const filters = [getTVFilter(counter[-1]), getTopFilter(counter[-2])]
  const byCount = counter[BY_FILTER_ID]
  return byCount ? [getBYFilter(byCount), ...filters] : filters
}
