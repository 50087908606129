import Footer from 'components/Footer'
import { Page } from 'components/Page'
import { Redirect } from 'components/Redirect'
import { paths } from 'constants/paths'
import PeriodsFilter from 'pages/Home/components/PeriodsFilter'
import SportsFilter from 'pages/Home/components/SportsFilter'
import {
  Outlet,
  useMatch,
} from 'react-router-dom'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const HomeLayout = () => {
  const match = useMatch('/:section/:sportId')

  if (!match) {
    return <Redirect to={paths.HOME} />
  }

  const { params: { section, sportId } } = match

  return (
    <Page
      bg="black"
      trackScroll
    >
      <SportsFilter
        section={section}
        sportId={sportId}
      />
      <PeriodsFilter />
      <section className={cn(styles.content, styles[section], styles[sportId])}>
        <Outlet />
      </section>
      <Footer />
    </Page>
  )
}
