import defaultImg from 'assets/images/casino/default-slot.png'
import { IOS_APP_URL } from 'constants/links'
import { isIos } from 'constants/platform'
import { GameMode } from 'pages/Casino/constants'
import { GameParam } from 'pages/Casino/Game/utils'

const CASINO_REGEXP    = /^\/casino.*/i
const CASINO_GAMES_URI = '/casino/games'

export const onError = ({ target }) => {
  target.src = defaultImg
}

export const getRtpPercent = (rtp, { rtp_from, rtp_to } = {}) => {
  try {
    return ((rtp - rtp_from) / (rtp_to - rtp_from)) * 100
  } catch {
    return 0
  }
}

export const getGameTo = ({ id, freeSpin, hasDemo = true, isAvailable = true }) => {
  if (isAvailable) {
    const url = new URL(window.location)

    url.searchParams.set(GameParam.ID, id)
    freeSpin && url.searchParams.set(GameParam.MODE, GameMode.FREE_SPIN)
    !hasDemo && url.searchParams.set(GameParam.NO_DEMO, 'true')

    const { pathname, search } = url

    return {
      pathname: CASINO_REGEXP.test(pathname) ? pathname : CASINO_GAMES_URI,
      search,
    }
  }

  return isIos && IOS_APP_URL
}
