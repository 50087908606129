import { ReactComponent as DepositIcon } from 'assets/images/casino/deposit.svg'
import { usePaymentsState } from 'hooks/usePaymentsState'
import { toBYN } from 'pages/UserHistoryPage/utils'
import { connect } from 'react-redux'
import styles from './styles.module.scss'

const PayBtn = ({ money, isVisible }) => {
  const { showModal } = usePaymentsState()

  const balance = (!!+money && isVisible && toBYN(money)) || null

  return (
    <div>
      <button
        className={styles.paymentBtn}
        onClick={showModal}
        aria-label="Payments"
      >
        <div className={styles.icon}>
          <DepositIcon />
        </div>
        <div className={styles.portraitBalance}>{balance}</div>
      </button>
      <div className={styles.landscapeBalance}>{balance}</div>
    </div>
  )
}

const mapStateToProps = state => ({
  money:     state.auth.money,
  isVisible: state.data.isShowBalance,
})

export default connect(mapStateToProps)(PayBtn)
