export const AUTH_PANEL_HEIGHT = 60

export const GamesParamKey = {
  CATEGORY:   'category',
  THEME:      'theme',
  PROVIDER:   'provider',
  AUTO_FOCUS: 'auto-focus',
  SEARCH:     'search',
}

export const handleStickyHeader = (filtersRef, prevTopRef) => {
  const filters = filtersRef.current
  const prevTop = prevTopRef.current

  if (filters) {
    const scrollTop = document.documentElement.scrollTop

    filters.setAttribute('data-hidden', scrollTop > 50 && scrollTop !== prevTop)

    prevTopRef.current = scrollTop
  }
}

export const handleScrollTopPosition = (filtersRef, prevTopRef, e) => {
  const isLeft  = e.touches[0].pageX < window.screen.width / 2
  const filters = filtersRef.current

  if (filters) {
    const scrollTop = document.documentElement.scrollTop
    filters.setAttribute('data-left', isLeft)
    prevTopRef.current = scrollTop
  }
}
