import { ReactDatePicker } from 'components/DatePicker/lazy'
import {
  forwardRef,
  useEffect,
  useState,
} from 'react'
import styles from './styles.module.scss'
import {
  dateToExp,
  expToDate,
  MIN_DATE,
} from './utils'

const TriggerBtn = forwardRef(({ value, className, onClick }, ref) => (
  <button
    ref={ref}
    className={className}
    disabled={!value}
    onClick={onClick}
  >
    {value || 'ММ/ГГ'}
  </button>
))

export const ExpirationInput = ({ value, onChange }) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    setSelected(expToDate(value))
  }, [value])

  return (
    <div>
      <ReactDatePicker
        selected={selected}
        onChange={date => onChange(dateToExp(date))}
        dateFormat="MM/yy"
        minDate={MIN_DATE}
        customInput={<TriggerBtn className={styles.triggerBtn} />}
        showMonthYearPicker
      />
    </div>
  )
}
