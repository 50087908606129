import { Bet } from 'components/Coupon/Line/Bet'
import TEXT from 'store/constants/text'
import styles from './styles.module.scss'

export const BetSystem = ({ onChange, item, value, max, isVip, totalCount }) => (
  <Bet
    id={item.system}
    title={`${TEXT.coupon.system} ${item.m} ${TEXT.coupon.from} ${item.n}.`}
    value={value}
    max={max}
    coefficient={item.total}
    description={`${TEXT.coupon.variants}: ${item.count}`}
    onChange={nextValue => onChange(item.system, nextValue)}
    isVip={isVip}
    className={styles.express}
    single={totalCount === 1}
  />
)
