import { ReactComponent as LeftBranchIcon } from 'assets/images/tournament/branch-left.svg'
import { ReactComponent as RightBranchIcon } from 'assets/images/tournament/branch-right.svg'
import styles from './styles.module.scss'

export const Place = ({ value, prize }) => (
  <div className={styles.place}>
    {prize ? (
      <>
        <LeftBranchIcon />
        <div className={styles.value}>{value}</div>
        <RightBranchIcon />
      </>
    ) : value}
  </div>
)
