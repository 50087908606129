import casinoLive from 'assets/images/casino/banners/casino-live.webp'
import fastGames from 'assets/images/casino/banners/fast-games.webp'
import jetX from 'assets/images/casino/banners/jet-x.webp'
import slots from 'assets/images/casino/banners/slots.webp'
import tournaments from 'assets/images/casino/banners/tournaments.webp'
import tvGames from 'assets/images/casino/banners/tv-games.webp'
import virtualSport from 'assets/images/casino/banners/virtual-sport.webp'
import { paths } from 'constants/paths'

export const getBanners = tournamentsCount => [
  { name: 'slots', image: slots, to: paths.CASINO_SLOTS },
  { name: 'jetx', image: jetX, to: paths.JET_X, state: { name: 'JetX' } },
  {
    name:  'tournaments',
    image: tournaments,
    to:    `${paths.TOURNAMENTS}?tab=1`,
    count: tournamentsCount,
  },
  { name: 'liveCasino', image: casinoLive, to: paths.CASINO_LIVE },
  { name: 'fastGames', image: fastGames, to: paths.QUICK_GAMES },
  { name: 'virtualSport', image: virtualSport, to: paths.VIRTUAL_SPORT },
  { name: 'tvGames', image: tvGames, to: paths.TV_GAMES },
].filter(Boolean)
