export const createCycleCounter = (from, to) => {
  let num = from

  return () => {
    const result = num++

    if (num === to + 1) {
      num = from
    }

    return result
  }
}
