import { Api } from 'api/Api'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import SlickSlider from 'react-slick'
import { cn } from 'utils'
import { settings } from './utils'

export const Slider = ({ slides, className }) => {
  const [isReady, setIsReady] = useState(false)

  return (
    !!slides?.length ? (
      <SlickSlider
        onInit={() => setIsReady(true)}
        className={cn('promo-slider', !isReady && 'preparing', className)}
        infinite={slides.length > 1}
        {...settings}
      >
        {slides.map(({ id, name, path_mobile, path_ru, href_mobile }) => (
          <Link
            to={href_mobile}
            key={id}
            onClick={() => Api.sendSlideStatistics(id)}
            className="promo-slider__slide"
          >
            <img
              src={`https://cdn-01.maxline.by/images/slider/${path_mobile || path_ru}`}
              className="promo-slider__img"
              alt={name}
            />
          </Link>
        ))}
      </SlickSlider>
    ) : null
  )
}
