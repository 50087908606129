import { ReactComponent as AppOnlyIcon } from 'assets/images/casino/app-only.svg'
import { Tags } from 'components/Casino/Tags'
import { isWebView } from 'constants/platform'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import styles from '../styles.module.scss'
import { RtpAnimations } from './RtpAnimations'
import {
  getGameTo,
  getRtpPercent,
  onError,
} from './utils'

export const Game = ({
  id,
  image,
  name,
  tags,
  freeSpin,
  hasDemo,
  appOnly,
  rtpValues,
  rtp,
  className,
}) => {
  const isAvailable     = !appOnly || isWebView
  const hasAppOnlyLabel = appOnly && !isWebView

  const to         = getGameTo({ id, freeSpin, hasDemo, isAvailable })
  const rtpPercent = getRtpPercent(rtp, rtpValues)

  const Tag = to ? Link : 'div'

  return (
    <div className={cn(styles.game, className)}>
      <Tags
        list={tags}
        className={styles.tags}
      />
      <Tag
        className={styles.link}
        title={name}
        state={{ name }}
        to={to}
      >
        {rtp && <RtpAnimations percent={rtpPercent} />}
        <img
          className={styles.image}
          src={image}
          alt={name}
          onError={onError}
        />
        {hasAppOnlyLabel && <AppOnlyIcon className={styles.appOnlyIcon} />}
      </Tag>
    </div>
  )
}
