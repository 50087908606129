export const paths = {
  HOME:               '/',
  LIVE_TOP:           '/live/top',
  LINE_ALL:           '/line/all-sports',
  ACCOUNT:            '/user',
  PAYMENTS:           '/user/pay',
  PAYMENTS_PAGE:      '/page/payments',
  SIGN_UP:            '/user/signup',
  FORGOT_PASSWORD:    '/user/forgot-password',
  VERIFICATION:       '/user/upload-document',
  USER_HISTORY:       '/user/history',
  BETS_HISTORY:       '/user/history/bets',
  CASINO_HISTORY:     '/user/history/casino',
  PAYMENTS_HISTORY:   '/user/history/payments',
  FORECASTS_HISTORY:  '/user/history/forecasts?filter=2',
  CASINO_SLOTS:       '/casino/slots',
  CASINO_SLOTS_GAMES: '/casino/games',
  CASINO_LIVE:        '/casino/live',
  CASINO_MY_GAMES:    '/casino/slots/my-games',
  TV_GAMES:           '/casino/tv-games',
  CASINO_HOME:        '/casino',
  CASINO_ML_PAY:      '/casino/maxline_pay',
  JET_X:              '/casino/games?game=5238',
  QUICK_GAMES:        '/casino/games?category=106',
  VIRTUAL_SPORT:      '/casino/virtual-sport',
  BET_GAMES:          '/betgames',
  TV_BET:             '/tvbet',
  RANDOM_BONUS:       '/random-bonus',
  FORECAST:           '/forecast/create',
  TOURNAMENTS:        '/prize-tournament',
  BONUSES:            '/pages/bonuses',
  TOP_EXPRESS:        '/top-express',
  HIGHLIGHTS:         '/highlights',
  MAXLINE_PAY_RETURN: '/maxline-pay-return',
  BONUS22:            '/deposit-bonus',
  VIP_CASHBACK:       '/vip-cashback',
  NEWS:               '/news/site?tab=45',
  MOBILE_APP:         '/page/mobile-app',
}
