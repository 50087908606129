import { StepTree } from 'components/PaymentModal/ERIP/Steps'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectID } from 'store/auth/selectors'
import { cn } from 'utils'
import { copyToClipboard } from 'utils/clipbord'
import styles from './styles.module.scss'

export const ByERIP = () => {
  const [isCopied, setIsCopied] = useState(false)

  const accID = useSelector(selectID)

  const onCopy = async ({ currentTarget: { id } }) => {
    await copyToClipboard(id)
    setIsCopied(true)
  }

  return (
    <div className={styles.erip}>
      <div className={styles.title}>Войдите в “Расчёт” (ЕРИП) в приложении банка:</div>
      <StepTree steps={['Интернет-магазины / сервисы', 'Виртуальные игорные заведения', 'maxline.by']} />
      <div className={styles.divider} />
      <div className={styles.footer}>
        <div className={styles.subtitle}>Номер Вашего игрового счёта:</div>
        <button
          id={accID}
          onClick={onCopy}
          className={cn(styles.accID, isCopied && styles.copied)}
        >
          {accID}
        </button>
      </div>
    </div>
  )
}