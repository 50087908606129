import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'

export const onInitChange = payload => ({
  type: ACTIONS.SYSTEM.INITIALIZED,
  payload,
})

export const changeTheme = payload => dispatch => {
  dispatch({
    type: ACTIONS.SYSTEM.CHANGE_THEME,
    payload,
  })
}

export const setBetOnOur = payload => dispatch => dispatch({
  type: ACTIONS.SYSTEM.SET_BET_ON_OUR,
  payload,
})

export const getTournamentsCount = () => async dispatch => {
  try {
    const { data: { count_casino } } = await Api.getTournamentsCount()
    dispatch({ type: ACTIONS.SYSTEM.TOURNAMENTS_COUNT, payload: count_casino })
  } catch (err) {
    console.error(err)
  }
}

export const setNotCalcBets = payload => ({
  type: ACTIONS.SYSTEM.NOT_CALC_BETS,
  payload,
})

export const getNotCalcBets = () => async (dispatch, getStore) => {
  const { auth: { isAuth } } = getStore()

  if (isAuth) {
    try {
      const { data: { count } } = await Api.getNotCalcBets()
      return dispatch(setNotCalcBets(count))
    } catch (err) {
      console.error(err)
    }
  }
}
