import SportIcon from 'components/SportIcon'
import SwipeModal from 'components/SwipeModal'
import { getBetStyles } from 'pages/UserHistoryPage/utils'
import { useState } from 'react'
import styles from './styles.module.scss'

export const UserBets = ({ items, user }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        className={styles.showBetBtn}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Show bet"
      />
      <SwipeModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          <>
            <div className="font-normal font-12 gray">Ставка игрока:</div>
            <div className="font-14">{`${user.name} (${user.id})`}</div>
          </>
        }
      >
        {items.map(({ name, sport_id, status, teams, value }) => (
          <div className={styles.bet}>
            <SportIcon id={sport_id} />
            <div className={styles.main}>
              <div className="bold">{teams}</div>
              <div className="gray font-12">{name}</div>
            </div>
            <div className="mr-10 bold lh-14">{value}</div>
            <img
              src={getBetStyles(status).img}
              alt="Bet item status"
            />
          </div>
        ))}
      </SwipeModal>
    </>
  )
}
