import { Api } from 'api/Api'
import { showErrorModal } from 'components/Popup'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import FailAuthModal from 'components/SignUp/ConfirmPhone/FailAuthModal'
import {
  getCredentialsFromLS,
  removeCredentialFromLS,
} from 'components/SignUp/utils'
import { paths } from 'constants/paths'
import {
  DEFAULT_PLATFORM,
  platform,
} from 'constants/platform'
import { STATUSES } from 'constants/statuses'
import { checkIsAuth } from 'store/auth/actions'
import {
  getCashback,
  setCashback,
} from 'store/cashback/actions'
import ACTIONS from 'store/constants/actions'
import TEXT from 'store/constants/text'
import {
  getNotCalcBets,
  setNotCalcBets,
} from 'store/data/actions'
import {
  accountSettings,
  checkRandomBonus,
} from 'store/data/reducers'
import { addModal } from 'store/modal/actions'
import { getUserBets } from 'store/user-bets/actions'
import {
  userEmitter,
  UserEvent,
} from 'utils/emitters/user'
import {
  setRegistration,
  setSignIn,
  setSignUpError,
  setSignUpSuccess,
} from 'utils/google-analytics/EcommerceGTM'
import { postMessageToRN } from 'utils/react-native'
import { reactiveStorage } from 'utils/reactive-storage'

const accountsKey = 'PROD-ACCOUNTS'

export const getAccounts = () => reactiveStorage.getItem(accountsKey)

const saveAccId = accId => {
  const accounts = getAccounts()?.split(',') ?? []

  if (!accounts.includes(String(accId))) {
    reactiveStorage.setItem(accountsKey, [...accounts, accId].join(','))
  }
}

export const getPromoCodesCount = () => async dispatch => {
  try {
    const { data: { count } } = await Api.getPromoCodesCount()
    dispatch({ type: ACTIONS.USER.SET_PROMO_CODES_COUNT, payload: count })
  } catch (error) {
    console.error(error)
  }
}

export const getInfo = () => dispatch => {
  dispatch(getUserBets())

  Api.getInfo().then(({ data = {} } = {}) => {
      dispatch({
        type: ACTIONS.USER.GET_INFO,
        data,
      })
    },
  )

  Api.getOrderedPayment().then(({ data } = {}) => {
      dispatch({
        type:   ACTIONS.USER.SET_ORDERED_PAYMENT,
        amount: data?.amount ?? 0,
      })
    },
  )
}

export const signIn = (login, password, options) => async dispatch => {
  const { showError = true, onEnd, onSuccess, onError } = options

  showPreloader()

  try {
    const { data, status, messages } = await Api.login(login, password)

    const isOk = status === STATUSES.SUCCESS

    dispatch({ type: ACTIONS.USER.LOGIN, data })
    await dispatch(checkIsAuth())

    hidePreloader()

    if (isOk) {
      const isMobile    = platform === DEFAULT_PLATFORM
      const { user_id } = data

      if (isMobile) {
        setSignIn(user_id)
        OneSignalDeferred.push(OneSignal => OneSignal.login(String(user_id)))
      } else {
        postMessageToRN({ userId: user_id })
      }

      dispatch(getInfo())
      dispatch(accountSettings())
      dispatch(getCashback())
      dispatch(checkRandomBonus())
      dispatch(getNotCalcBets())
      saveAccId(user_id)
      onSuccess?.()
      userEmitter.emmit(UserEvent.AUTH)
    } else {
      onError?.()

      if (showError) {
        showErrorModal({ message: messages || TEXT.login.loginError })
      }
    }

    return isOk
  } catch {
    onError?.()

    return false
  } finally {
    onEnd?.()
  }
}

export const confirmPhoneCode = (code, push, time, promoCode, onSuccess) => async dispatch => {
  showPreloader()

  try {
    const { data, status } = await Api.verifyPhoneCode({ code, promoCode })

    hidePreloader()

    if (status === STATUSES.SUCCESS) {
      const { userId }          = data
      const { password, phone } = getCredentialsFromLS()

      setSignUpSuccess(time, userId)
      removeCredentialFromLS()

      const loginResult = await dispatch(signIn(phone, password, { showError: false }))

      if (loginResult) {
        setRegistration()
        onSuccess?.(userId)
      } else {
        push(paths.HOME)
        setTimeout(() => dispatch(addModal(FailAuthModal.ID, <FailAuthModal userId={userId} />), 0))
      }
    }

    if (status === STATUSES.ERROR) {
      showErrorModal({ message: data.messages })
      setSignUpError()
    }
  } catch (e) {
    console.error(e)
  }
}

export const logOut = () => dispatch => {
  reactiveStorage.removeItem('token')
  reactiveStorage.removeItem('user')
  reactiveStorage.removeItem('coupon')

  dispatch({ type: ACTIONS.AUTH.LOGOUT })
  dispatch({ type: ACTIONS.USER.LOGOUT })
  dispatch({ type: ACTIONS.SYSTEM.RESET_CHANGE_KOEFF })
  dispatch({ type: ACTIONS.COUPON.RESET })
  dispatch(checkRandomBonus())
  dispatch(setCashback())
  dispatch(setNotCalcBets(0))
}
