import failedAnimation from 'assets/lottie/bet-failed.json'
import successAnimation from 'assets/lottie/bet-success.json'
import {
  getBetInfo,
  MODAL_TTL,
} from 'components/BetFinished/utils'
import { Lottie } from 'components/Lottie'
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { clearBetData } from 'store/current_bet/actions'

const BetFinished = ({
  error,
  title,
  subTitle,
  type,
  amount,
  payout,
  koeff,
  isExist,
  clearBetData,
}) => {
  const [secToClose, setSecToClose] = useState(MODAL_TTL)

  const intervalRef = useRef(0)
  const timeoutRef  = useRef(0)

  const clearTimers = () => {
    clearTimeout(timeoutRef.current)
    clearInterval(intervalRef.current)
  }

  useEffect(() => {
    if (isExist) {
      intervalRef.current = setInterval(() => setSecToClose(prev => prev - 1), 1000)
      timeoutRef.current  = setTimeout(clearBetData, MODAL_TTL * 1000)
    }

    if (!isExist) {
      clearTimers()
      setSecToClose(MODAL_TTL)
    }

    return clearTimers
  }, [isExist])

  return (
    isExist && (
      <>
        <div className="bet-finished">
          <div className="bet-finished__content">
            <div className="bet-finished__status-img">
              <Lottie src={error ? failedAnimation : successAnimation} />
            </div>
            <div className="bet-finished__title">
              {title}
            </div>
            <div className="bet-finished__subtitle">
              {subTitle}
            </div>
            {type &&
              <div className="bet-finished__type">
                {type}
              </div>}
            {!error &&
              <div className="bet-finished__info">
                <div
                  className="bet-finished__info-item"
                  data-name="Сумма:"
                >
                  {amount}
                </div>
                <div
                  className="bet-finished__info-item"
                  data-name="Коэффициент:"
                >
                  {koeff}
                </div>
                <div
                  className="bet-finished__info-item"
                  data-name="Возможный выигрыш:"
                >
                  {payout}
                </div>
              </div>}
            <button
              className="ml-button bet-finished__btn"
              onClick={clearBetData}
            >
              {error ? 'Повторить' : 'Продолжить'}
            </button>
            <div className="bet-finished__close-in">
              {`Будет закрыто через: ${secToClose} сек.`}
            </div>
          </div>
        </div>
      </>
    )
  )
}

const mapStateToProps = state => getBetInfo(state.bet_data.data)

const mapDispatchToProps = {
  clearBetData,
}

export default connect(mapStateToProps, mapDispatchToProps)(BetFinished)
