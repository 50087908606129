import { Tags } from 'components/Casino/Tags'
import styles from './styles.module.scss'

export const Filters = ({ items, onClick, activeId, link }) => (
  <div className="casino-filters">
    {items.map(({ id, icon, name, tags }) => (
      <div
        key={id}
        onClick={() => onClick(id)}
        data-id={id}
        className="casino-filters__item"
      >
        {icon && (
          <div className="casino-filters__icon-wrapper">
            <img
              className="casino-filters__icon"
              src={icon}
              alt={name}
            />
          </div>
        )}
        <Tags
          list={tags}
          className={styles.tags}
        />
        <span className="casino-filters__name">{name}</span>
        {!link && (<div
            className="casino-filters__radiobutton"
            data-checked={String(activeId) === String(id)}
          />
        )}
      </div>
    ))}
  </div>
)
