import { OplatiTerms } from 'components/OplatiTerms'
import { Portrait } from 'components/Orientation'
import { useAmountController } from 'components/PaymentModal/AmountController'
import { MIN_AMOUNT } from 'components/PaymentModal/AmountController/utils'
import { OplatiErrorMessage } from 'components/PaymentModal/Oplati/ErrorMessage'
import { OplatiLink } from 'components/PaymentModal/Oplati/OplatiLink'
import { useOplatiPayment } from 'components/PaymentModal/Oplati/utils'
import { OtherPaymentsLink } from 'components/PaymentModal/OtherPaymentsLink'
import { SubmitBtn } from 'components/SubmitBtn'
import styles from './styles.module.scss'

export const ByOplati = () => {
  const { onSubmit, paymentLink, statusMessage }                   = useOplatiPayment()
  const [AmountController, { value, onKeyboardClose, onValidate }] = useAmountController()

  const onHandleSubmit = () => {
    onValidate()
    onKeyboardClose()

    if (value >= MIN_AMOUNT) {
      return onSubmit(value)
    }
  }

  return (
    <div className={styles.oplati}>
      {paymentLink ? <OplatiLink to={paymentLink} /> : (
        <>
          {AmountController}
          <SubmitBtn
            className="ml-button"
            onClick={onHandleSubmit}
          >
            Пополнить
          </SubmitBtn>
          <OplatiTerms
            type="pay"
            className={styles.link}
          />
          <Portrait>
            <OtherPaymentsLink />
          </Portrait>
        </>
      )}
      <OplatiErrorMessage
        statusMessage={statusMessage}
        link={paymentLink}
      />
    </div>
  )
}