import { AppProvider } from 'components/AppProvider'
import { ROOT } from 'constants/ids'
import { setDefaultOptions } from 'date-fns'
import { ru } from 'date-fns/locale'
import moment from 'moment'
import React from 'react'
import { render } from 'react-dom'
import { initSentry } from 'utils/sentry'
import 'moment/locale/ru'

initSentry()
setDefaultOptions({ locale: ru })
moment.locale('ru')

render(<AppProvider />, document.getElementById(ROOT))
