import { useIsLandscape } from 'hooks/useIsLandscape'
import { Fragment } from 'react'

export const Orientation = ({ landscape, children, ...restProps }) => {
  const isLandscape = useIsLandscape()

  const Tag = Object.keys(restProps).length ? 'div' : Fragment

  return (isLandscape === landscape) ? <Tag {...restProps}>{children}</Tag> : null
}

export const Portrait  = props => <Orientation landscape={false} {...props} />
export const Landscape = props => <Orientation landscape {...props} />
