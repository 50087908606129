import { Api } from 'api/Api'
import { GameHeader } from 'components/Casino/Game/Header'
import { CasinoSpinner } from 'components/Casino/Spinner'
import { Placeholder } from 'components/Placeholder'
import { useAuthModal } from 'hooks/useAuthModal'
import { GameMode } from 'pages/Casino/constants'
import {
  CASINO_NAVIGATE_OPTS,
  GameError,
  GameParam,
  getGameError,
} from 'pages/Casino/Game/utils'
import {
  useLayoutEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getFavorites } from 'store/casino/actions'
import { cn } from 'utils'
import styles from './styles.module.scss'

const CasinoGamePage = ({ id, isAuth, getFavorites }) => {
  const [url, setUrl]                   = useState('')
  const [errorReason, setErrorReason]   = useState(null)
  const [isFetching, setIsFetching]     = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [tournament, setTournament]     = useState({})
  const [searchParams, setSearchParams] = useSearchParams()

  const openAuthModal = useAuthModal()

  const hasDemo = searchParams.get(GameParam.NO_DEMO) !== 'true'
  const mode    = searchParams.get(GameParam.MODE)

  const isAvailable = isAuth || (hasDemo && mode)

  const getTournament = () => {
    Api.getTournament(id)
      .then(({ data }) => data && setTournament(data))
      .catch(console.error)
  }

  // useListener('message', message => {
  //     if (message.data?.mlReadyState === DocumentReadyState.LOADING) {
  //         navigate(hasDemo ? paths.CASINO_SLOTS : paths.CASINO_LIVE, CASINO_NAVIGATE_OPTS)
  //     }
  // })

  useLayoutEffect(() => {
    if (isAvailable) {
      setErrorReason(null)
      setIsFetching(true)

      Api.initCasinoGame(id, mode)
        .then(({ data: { url }, code }) => url ? setUrl(url) : setErrorReason(getGameError(code)))
        .catch(() => setErrorReason(GameError.NOT_FOUND))
        .finally(() => setIsFetching(false))

      getTournament()

      if (isAuth) {
        getFavorites()
      }
    } else {
      setErrorReason(GameError.NOT_AUTH)
      setUrl('')
      openAuthModal()
    }
  }, [id, isAuth, mode])

  const onChangeDemo = () => {
    if (mode) {
      searchParams.delete(GameParam.MODE)
    } else {
      searchParams.set(GameParam.MODE, GameMode.DEMO)
    }

    setSearchParams(searchParams, CASINO_NAVIGATE_OPTS)
  }

  const TopPlaceholder = () => {
    if (!mode) {
      return null
    }

    return (
      <div
        className={styles.playMoneyBtn}
        onClick={onChangeDemo}
      >
        Играть на реальные деньги
      </div>
    )
  }

  return (
    <>
      <TopPlaceholder />
      <section className={cn(styles.gamePage, isFullScreen && styles.fullScreen, mode && styles.withTopPlaceholder)}>
        <GameHeader
          gameId={id}
          mode={mode}
          hasDemo={hasDemo}
          onChangeDemo={onChangeDemo}
          tournament={tournament}
          updateTournament={getTournament}
          fullScreen={isFullScreen}
          setFullScreen={setIsFullScreen}
        />
        {(isFetching && <CasinoSpinner />) ||
          (errorReason && <Placeholder name={errorReason} />) ||
          (url && (
            <iframe
              className={styles.gameIframe}
              src={url}
              onError={() => setErrorReason(GameError.NOT_FOUND)}
            />
          ))}
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
})

const mapDispatchToProps = {
  getFavorites,
}

export default connect(mapStateToProps, mapDispatchToProps)(CasinoGamePage)
