import { ReactComponent as CoinIcon } from 'assets/images/casino/coin.svg'
import { ReactComponent as TournamentIcon } from 'assets/images/casino/tournament.svg'
import { ReactComponent as TrophyIcon } from 'assets/images/casino/trophy.svg'
import { ModalType } from 'components/Casino/Game/Header/TournamentBtn/utils'
import { useCentrifugoSub } from 'components/Centrifugo'
import { ExternalLink } from 'components/ExternalLink'
import SwipeModal from 'components/SwipeModal'
import { useAuthModal } from 'hooks/useAuthModal'
import { TournamentHeader } from 'pages/Tournament/Header'
import { TournamentResults } from 'pages/Tournament/Results'
import {
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { selectAuth } from 'store/auth/selectors'
import { CentrifugoChannel } from 'utils/centrifugo/utils'
import styles from './styles.module.scss'

const PlaceBtn = ({ your, onClickCoin, onClickTrophy }) => {
  if (!your || !your?.scores?.value) {
    return null
  }

  return (
    <div className={styles.placeBtn}>
      <div
        className={styles.value}
        onClick={onClickCoin}
      >
        <CoinIcon />
        <span>{your.scores.value}</span>
      </div>
      <div
        className={styles.value}
        onClick={onClickTrophy}
      >
        <TrophyIcon />
        <span>{your.i}</span>
      </div>
    </div>
  )
}

export const TournamentBtn = ({
  table,
  onUpdate,
  prize_tournament,
  prize_tournament: { id, topic, conditions } = {},
}) => {
  const [modalType, setModalType] = useState(ModalType.NONE)

  const { isAuth, id: user }    = useSelector(selectAuth)
  const openAuthModal           = useAuthModal()
  const { table: updatedTable } = useCentrifugoSub(CentrifugoChannel.TOURNAMENT_PLACE, { id, user })

  const onClose = () => setModalType(ModalType.NONE)

  const onTableOpen = () => {
    onUpdate()
    setModalType(ModalType.TABLE)
  }

  const modalRef = useRef({ onClose })

  const isModalOpen  = modalType !== ModalType.NONE
  const isInfoModal  = modalType === ModalType.INFO
  const isTableModal = modalType === ModalType.TABLE

  const onClick = () => {
    if (isAuth) {
      onUpdate()
      return setModalType(ModalType.INFO)
    }

    openAuthModal()
  }

  const onTableReopen = () => modalRef.current.onClose(onTableOpen)

  const tournamentTable = updatedTable || table

  const Button = () => {
    if (tournamentTable?.your?.i) {
      return (
        <PlaceBtn
          onClickCoin={onClick}
          onClickTrophy={onTableOpen}
          {...tournamentTable}
        />
      )
    }

    return (
      <div
        className={styles.tournamentBtn}
        onClick={onClick}
      >
        <TournamentIcon />
        <div className={styles.tournamentNameContainer}>
          <div className={styles.tournamentName}>Турнир</div>
          <div className={styles.name}>{topic}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.tournamentBtnContainer}>
        <Button />
      </div>
      <SwipeModal
        isOpen={isModalOpen}
        onClose={onClose}
        dark={modalType === ModalType.INFO}
        innerRef={modalRef}
      >
        {isInfoModal && (
          <div className={styles.infoModal}>
            <TournamentHeader
              auth={isAuth}
              onUpdate={onUpdate}
              sticky={false}
              {...prize_tournament}
            />
            <button
              className={styles.tableBtn}
              onClick={onTableReopen}
            >
              Турнирная таблица
            </button>
            <ExternalLink
              className={styles.rulesLink}
              to={conditions}
            >
              Условия участия
            </ExternalLink>
          </div>
        )}
        {isTableModal && (
          <TournamentResults {...tournamentTable} className={styles.results}>
            {tournamentTable.your && <div className={styles.title}>Ваша позиция:</div>}
          </TournamentResults>
        )}
      </SwipeModal>
    </>
  )
}
