import { AllFavorites } from 'components/Casino/Favorites/AllFavorites'
import { Search } from 'components/Casino/Search/Search'
import { SelectBlock } from 'components/Casino/SelectBlock'
import { InfiniteSlots } from 'components/Casino/Slots/InfiniteSlots'
import { CasinoSpinner } from 'components/Casino/Spinner'
import { Page } from 'components/Page'
import { Placeholder } from 'components/Placeholder'
import { Slider } from 'components/Slider'
import { useAuth } from 'hooks/useAuth'
import {
  AllFilter,
  HEATED_CATEGORY_ID,
  QUICK_GAMES_ID,
} from 'pages/Casino/constants'
import { GameParam } from 'pages/Casino/Game/utils'
import TopHeatedGames from 'pages/Casino/Games/Top'
import {
  AUTH_PANEL_HEIGHT,
  GamesParamKey,
  handleScrollTopPosition,
  handleStickyHeader,
} from 'pages/Casino/Games/utils'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  getCategories,
  getCounters,
  getGames,
  getSlides,
} from 'store/casino/actions'
import {
  resetCasinoData,
  resetCasinoState,
  setActiveID,
  setGamesList,
  setPageId,
} from 'store/casino/creators'
import { scrollTop } from 'utils'
import styles from './styles.module.scss'

const CasinoGamesPage = ({
  loading,
  pageId,
  games,
  has_more,
  setPageId,
  getGames,
  getCategories,
  getCounters,
  setActiveID,
  resetCasinoData,
  slides,
  getSlides,
}) => {
  const [isSearching, setIsSearching] = useState(false)

  const [searchParams] = useSearchParams()

  const isAuth = useAuth()

  const filtersRef = useRef()
  const prevScroll = useRef(0)

  const categoryQuery = searchParams.get(GamesParamKey.CATEGORY)
  const themeQuery    = searchParams.get(GamesParamKey.THEME)
  const providerQuery = searchParams.get(GamesParamKey.PROVIDER)
  const gameQuery     = searchParams.get(GameParam.ID)
  const isQuickGames  = categoryQuery === QUICK_GAMES_ID
  const isHeated      = categoryQuery === HEATED_CATEGORY_ID

  const requestGames = useCallback(() => {
    getGames()
    setIsSearching(false)
  }, [])

  const showFilters = () => {
    filtersRef.current.removeAttribute('data-hidden')
    prevScroll.current = document.documentElement.scrollTop
  }

  useEffect(() => {
    if (isQuickGames) {
      getSlides({ position: 8 })
    }
  }, [isQuickGames])

  useEffect(() => {
    setActiveID({
      category: categoryQuery || AllFilter.id,
      provider: providerQuery || AllFilter.id,
      theme:    themeQuery || AllFilter.id,
    })

    getCategories()
    getCounters()
    requestGames()
  }, [categoryQuery, themeQuery, providerQuery])

  useLayoutEffect(() => {
    resetCasinoData()
  }, [])

  useEffect(() => {
    if (!gameQuery) {
      const onScroll     = () => handleStickyHeader(filtersRef, prevScroll)
      const onTouchStart = event => handleScrollTopPosition(filtersRef, prevScroll, event)

      window.addEventListener('scroll', onScroll)
      window.addEventListener('touchstart', onTouchStart)

      return () => {
        window.removeEventListener('scroll', onScroll)
        window.removeEventListener('touchstart', onTouchStart)
      }
    }
  }, [gameQuery])

  const onEnter = () => {
    setIsSearching(true)
    setPageId(0)
    requestGames()
  }

  const getMore = () => {
    if (has_more) {
      setPageId(pageId + 1)
      getGames({ isAddGames: true })
    }
  }

  const topGames     = isHeated ? games.slice(0, 3) : []
  const regularGames = isHeated ? games.slice(3) : games

  return (
    <Page
      className="casino"
      bg="black"
      headerContent={(
        <div className={styles.header}>
          <Search
            onEnter={onEnter}
            className={styles.search}
          />
          <AllFavorites />
        </div>
      )}
    >
      {isHeated && <h2 className={styles.heatedTitle}>Разогретые слоты</h2>}
      {isQuickGames && (
        <Slider
          slides={slides}
          className="mb-8"
        />
      )}
      <div
        ref={filtersRef}
        className={styles.filters}
        style={{ '--bottom-threshhold': `${isAuth ? 0 : AUTH_PANEL_HEIGHT}px` }}
      >
        <div className={styles.selects}>
          <SelectBlock />
        </div>
        <div className={styles.controls}>
          <button
            className={styles.toggleBtn}
            onClick={showFilters}
            aria-label="Show filters"
          />
          <button
            className={styles.scrollTopBtn}
            onClick={scrollTop}
            aria-label="Scroll top"
          />
        </div>
      </div>
      {isHeated && <TopHeatedGames list={topGames} />}
      {!!games.length && (
          <InfiniteSlots
            games={regularGames}
            getMore={getMore}
            loading={loading}
            className={styles.games}
          />
        )
        || ((loading || isSearching) && <CasinoSpinner />)
        || <Placeholder name="gamesSearch" />}
    </Page>
  )
}

const mapStateToProps = state => ({
  loading:  state.casino.loadings.games,
  pageId:   state.casino.pageId,
  provider: state.casino.activeIds.provider,
  category: state.casino.activeIds.category,
  theme:    state.casino.activeIds.theme,
  games:    state.casino.games,
  has_more: state.casino.gamesInfo.has_more,
  slides:   state.casino.slides,
})

const mapDispatchToProps = {
  setPageId,
  getGames,
  setGamesList,
  getCategories,
  getCounters,
  setActiveID,
  resetCasinoState,
  resetCasinoData,
  getSlides,
}

export default connect(mapStateToProps, mapDispatchToProps)(CasinoGamesPage)
