import { useDidUpdate } from 'hooks/useDidUpdate'
import { useWillUnmount } from 'hooks/useWillUnmount'
import { useEffect } from 'react'

const html = document.documentElement
const body = document.body

const LOCK_SCROLL_CLASS = 'scroll-locked'

const getIsLocked = () => html.classList.contains(LOCK_SCROLL_CLASS)

const unlock = () => {
  if (getIsLocked()) {
    requestAnimationFrame(() => {
      const scrollY = -parseInt(body.style.top || '0')
      body.style.removeProperty('position')
      body.style.removeProperty('top')
      body.style.removeProperty('bottom')
      window.scrollTo(0, scrollY)
      html.classList.remove(LOCK_SCROLL_CLASS)
    })
  }
}

const lock = () => {
  if (!getIsLocked()) {
    return requestAnimationFrame(() => {
      const scrollY = -window.scrollY
      html.classList.add(LOCK_SCROLL_CLASS)
      html.style.scrollBehavior = 'unset'
      body.style.position       = 'fixed'
      body.style.top            = `${scrollY}px`
      body.style.bottom         = `0px`
    })
  }
}

export const useStopScroll = (condition = true) => {
  useEffect(() => {
    if (condition) {
      const isOk = lock()

      return () => {
        if (isOk) {
          unlock()
        }
      }
    }
  }, [condition])
}

export const useStopScroll2 = (condition = true) => {
  useEffect(() => {
    if (condition) {
      html.classList.add(LOCK_SCROLL_CLASS)
    }
  }, [])

  useDidUpdate(() => {
    html.classList[condition ? 'add' : 'remove'](LOCK_SCROLL_CLASS)
  }, [condition])

  useWillUnmount(() => html.classList.remove(LOCK_SCROLL_CLASS))
}
