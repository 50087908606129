import { ReactComponent as CloseIcon } from 'assets/images/casino/close.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/casino/expand.svg'
import { ReactComponent as FavoriteIcon } from 'assets/images/casino/favorite.svg'
import { ReactComponent as PlayIcon } from 'assets/images/casino/play.svg'
import { useFavorite } from 'components/Casino/Favorites/useFavorite'
import { TournamentBtn } from 'components/Casino/Game/Header/TournamentBtn'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { useIsLandscape } from 'hooks/useIsLandscape'
import { useState } from 'react'
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { cn } from 'utils'
import PayBtn from './PayBtn'
import styles from './styles.module.scss'

export const GameHeader = ({
  gameId,
  hasDemo,
  mode,
  onChangeDemo,
  fullScreen,
  setFullScreen,
  tournament,
  updateTournament,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const { isActive, toggleFavorite }      = useFavorite(gameId)

  const { state }   = useLocation()
  const navigate    = useNavigate()
  const isLandscape = useIsLandscape()

  const gameName         = state?.name
  const tournamentName   = tournament?.prize_tournament?.name
  const hasTournamentBtn = !mode && tournamentName

  const onCloseDetails = () => setIsDetailsOpen(false)

  const goBack = () => navigate(-1)

  const onChangeMode = () => {
    onCloseDetails()
    return hasDemo ? onChangeDemo() : goBack()
  }

  useDidUpdate(() => {
    if (!isLandscape) {
      setIsDetailsOpen(false)
    }
  }, [isLandscape])

  const changeModeBtn = () => {
    if (hasTournamentBtn) {
      return <TournamentBtn {...tournament} onUpdate={updateTournament} />
    }

    if (!mode) {
      return <div className={styles.gameName}>{gameName}</div>
    }

    return (
      <button
        onClick={onChangeMode}
        className={cn(styles.changeModeBtn, mode && styles.primary)}
      >
        Играть на деньги
      </button>
    )
  }

  const DemoBtn = () => {
    if (mode || !hasDemo) {
      return null
    }

    return (
      <button
        onClick={onChangeMode}
        className={cn(styles.detailsMenuItem, styles.demoBtn)}
      >
        <PlayIcon />
        <div>Демо</div>
      </button>
    )
  }

  const FullscreenBtn = () => (
    <button
      onClick={() => {
        setFullScreen(!fullScreen)
        onCloseDetails()
      }}
      className={cn(!fullScreen && styles.detailsMenuItem, styles.fullScreenBtn, fullScreen && styles.fullScreenBtnSticky)}
    >
      {fullScreen ? <CloseIcon /> : <ExpandIcon />}
      {!fullScreen && <div>Развернуть</div>}
    </button>
  )

  if (fullScreen) {
    return <FullscreenBtn />
  }

  const FavoriteBtn = () => {
    if (!hasDemo) {
      return null
    }

    return (
      <button
        onClick={toggleFavorite}
        className={cn(styles.detailsMenuItem, styles.favoriteBtn, isActive && styles.active)}
      >
        <FavoriteIcon />
        {isDetailsOpen && <div>{isActive ? 'В избранном' : 'Добавить в избранное'}</div>}
      </button>
    )
  }

  const DropdownMenu = () => (
    <div className={styles.detailsMenu}>
      <FullscreenBtn />
      <FavoriteBtn />
      <DemoBtn />
    </div>
  )

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <button
          className={styles.backBtn}
          aria-label="Go back"
          onClick={goBack}
        />
        {changeModeBtn()}
      </div>
      <PayBtn />
      <div className={styles.details}>
        <button
          className={styles.detailsBtn}
          aria-label="More"
          onClick={() => setIsDetailsOpen(!isDetailsOpen)}
        />
        {isDetailsOpen && <DropdownMenu />}
      </div>
    </div>
  )
}
