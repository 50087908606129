import React from 'react'

const TEXT = {
  app:          {
    undefinedTitle: 'Внимание!',
    name:           'Букмекерская компания Макслайн',
  },
  line:         {
    popular: 'Популярные',
    az:      'Все события',
  },
  liveSoon:     {
    title: 'Live скоро',
  },
  results:      {
    title:        'Результаты',
    results_live: 'Результаты LIVE',
    results_line: 'Результаты Спорт',
    chooseSport:  'Выберите спорт',
    showEvent:    'Показать событие',
    chooseData:   'Выберите дату',
    clearButton:  'Очистить все',
    select:       'Выбрано',
  },
  preloader:    {
    loadingText:  'Загрузка данных...',
    addCoupon:    'Добавление позиции в купон...',
    delCoupon:    'Удаление позиции из купона...',
    getWinners:   'Получение победителей',
    getForecast:  'Получение событий для прогнозов',
    makeForecast: 'Выполнение операции...',
    loadingNews:  'Загрузка списка новостей ...',
  },
  coupon:       {
    clearTitle:         'Очистка купона',
    processing:         'Выполнение операции...',
    betInPending:       'Принятие ставки...',
    system:             'Система',
    from:               'из',
    variants:           'Вариантов',
    max:                'Max',
    minShort:           'Мin',
    min:                'Минимум',
    totalKoeff:         'Общ. коэфф',
    potential_winnings: 'Потенциальный выигрыш',
    possibleWinShort:   'Возм. выигрыш',
    possibleWin:        'Возможный выигрыш',
    makeBet:            'Сделать ставку',
  },
  login:        {
    loginTitle:          'Авторизация',
    loginText:           'Введите Ваши логин и пароль',
    loginError:          'Ошибка авторизации',
    usernamePlaceholder: 'Логин',
    passwordPlaceholder: 'Пароль',
  },
  pays:         {
    generatingLink: 'Генерируем ссылку',
    paysTitle:      'Быстрая выплата',
    paysSuccess:    'Выплата прошла успешно',
  },
  register:     {
    title: 'Регистрация',
  },
  account:      {
    vipCashback: {
      title: 'VIP CashBack',
    },
    payout:      {
      title: 'Заказать на выплату',
    },
    messages:    {
      title: 'Сообщения',
    },
    promoCodes:  {
      title:   'Применить промокод',
      history: 'История промокодов',
    },
    messageItem: {
      title: 'Назад',
    },
    betHistory:  {
      title:            'История ставок',
      bet:              'Ставка',
      bet_not_calc:     'Возм. выигрыш',
      betNumber:        'Ставка №',
      betStatus:        'Статус',
      betCalculated:    'разыгранные',
      betNotCalculated: 'неразыгранные',
      notCalculated:    'Не рассчитана',
      win:              'Выигрыш',
      payment:          'Выплата',
      lost:             'Проигрыш',
      refused:          'Отклонена',
      considered:       'На рассмотрении',
      return:           'Возврат',
      possibleWin:      'Возможный выигрыш',
      none:             '',
    },
    tvBetHistory:    {
      title: 'История операций TVBET',
    },
    betGamesHistory: {
      title: 'История операций BetGames',
    },
    paymentHistory:  {
      title:  'История платежей',
      amount: 'Сумма',
    },
    changePassword:  {
      title: 'Изменение пароля',
    },
    personalData:    {
      title:             'Личные данные',
      account_number:    'Номер счёта',
      phone:             'Мобильный телефон',
      surname:           'ФИО',
      name:              'Имя',
      patronymic:        'Отчество',
      e_mail:            'Электронная почта',
      sity:              'Город',
      serial_number:     'Серия и номер паспорта',
      issued_by:         'Кем выдан',
      when_issued:       'Когда выдан',
      identity_document: 'ДОКУМЕНТ УДОСТОВЕРЯЮЩИЙ ЛИЧНОСТЬ',
      accountStatus:     'Статус игрового счёта',
    },
    takeMoney:       {
      title: 'Заказать на выплату',
    },
    pay:             {
      title: 'Пополнить счёт',
    },
    loadPassport:    {
      title: 'Загрузить документы',
      load:  'Отправка документов',
    },
    settings:        {
      title: 'Настройки аккаунта',
    },
    bonusHistory:    {
      title: 'История бонусов',
    },
    authHistory:     {
      title: 'История сеансов',
    },
    verification:    {
      title: 'Верификация телефона',
    },
    forecastHistory: {
      title: 'История прогнозов',
    },
    cashback:        {
      title: 'Кэшбэк',
    },
    help:            {
      title: 'Правила и документы',
    },
    faq:             {
      title: 'F.A.Q',
    },
    cashback20:      {
      title: 'PayoutCashback 20%',
    },
    logout:          'Выход',
  },
  about:        {
    about_us:    'О компании',
    title:       'О компании',
    pps:         'Пункты приёма ставок',
    workingTime: 'Время работы',
    address:     'Адрес',
    dayOff:      'Выходные',
  },
  mobApp:       {
    title: ' Приложение Maxline',
  },
  payments:     {
    title: 'Платежи',
  },
  payReturn:    {
    title: 'Maxline платит',
  },
  matchList:    {
    title: 'Список матчей',
  },
  news:         {
    title:        'Новости',
    company_news: 'Компании',
    sport_news:   'Спорта',
    analitics:    'Аналитика',
  },
  bonuses:      {
    title: 'Бонусы',
  },
  whats_new:    {
    title: 'Что нового',
  },
  search_event: {
    title:      'Поиск',
    processing: 'Выполнение операции...',
  },
  contacts:     {
    title:               'Контакты',
    hotline:             'Горячая линия',
    marketing:           'Маркетинг',
    workingTime:         'Время работы',
    roundTheClock:       'Круглосуточно',
    mts:                 'МТС',
    a1:                  'A1',
    email:               'E-mail',
    managementOtdel:     'Замечания и предложения напрямую руководству',
    buchWorkingTimeText: 'Для уточнения вопросов, связанных с выплатами',
    odnoklassniki:       'Одноклассники',
    facebook:            'Facebook',
    twitter:             'Twitter',

  },
  security:     {
    title: 'Безопасность платежей',
  },
  rules:        {
    title: 'Правила',
  },
  mns:          {
    title: 'МНС',
  },
  statute:      {
    title: 'Выписка из положения',
  },
  statement:    {
    title: 'Выписка из положения',
  },
  responsible:  {
    title: 'Ответственное отношение к азартным играм',
  },
  rules2:       {
    title: 'Правила посещения виртуального игорного заведения',
  },
  desktop:      {
    title: 'Веб-версия',
  },
  mobileApps:   {
    title: 'Мобильное приложение',
  },
  factors:      {
    noKoeff: <span>—</span>,
    stopped: <span className="event-stopped" />,
  },
  pps:          {
    title: 'Пункты приёма ставок',
  },
}

export default TEXT
